import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constants";
import toast from 'react-hot-toast'
import coverPhoto from '../assets/coverPhoto.webp'
import { jwtDecode } from "jwt-decode";

// Custom hook for debouncing
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const Store = () => {

  const token = localStorage.getItem('auth');
  const decoded = jwtDecode(token);
  const schoolId = decoded.org_id;


  const [userCourses, setUserCourses] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    state: false,
    courseId: null,
  });

  const [filteredData, setFilteredData] = useState([]); // Filtered school data for display
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Apply debouncing
  const navigate = useNavigate();

  const [dropdownState, setDropdownState] = useState({}); // State to track each dropdown's open state

  const [coverImages, setCoverImages] = useState({});

  function checkImageExists(imageUrl) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve();
      image.onerror = () => reject();
      image.src = imageUrl;
    });
  }


  const toggleDropdown = (courseId) => {
    setDropdownState(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId]
    }));
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const userId = localStorage.getItem('auth');
        if (userId) {
          const response = await axios.post(`${BASEURL}/api/store-courses`, { user_id: userId });
          const courseData = response.data.courseData;

          // Directly set the fetched courses without filtering
          setUserCourses(courseData);
          console.log('API Data:', courseData);
        }
      } catch (error) {
        console.error("Error fetching user courses:", error);
      }
    };

    fetchCourses();
  }, []);


  // Effect for filtering schools based on the debounced search term
  useEffect(() => {
    const filtered = userCourses.filter(course =>
      course.course_name && course.course_name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [debouncedSearchTerm, userCourses]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  // Function to handle importing a course
  const importCourse = async (courseId) => {
    try {
      // Check if the course is already imported
      const isAlreadyImported = userCourses.some(course => course.master_course_id === courseId);
      if (isAlreadyImported) {
        // If the course is already imported, display a toast message and return
        toast.error("Course already imported");
        return;
      }

      // If the course is not already imported, proceed with the import action
      const response = await axios.post(`${BASEURL}/api/import-course`, { 
        id: courseId ,  
        schoolId: schoolId // Include schoolId in the request payload
      });
      const { success } = response.data;
      if (success) {
        // Handle success, maybe show a toast or update UI
        toast.success("Course imported successfully!");
      }
    } catch (error) {
      console.error("Error importing course:", error);
      // Handle error, maybe show a toast or update UI
      toast.error("Uh-oh! You've already imported this course.");
    }
  };

  const fetchCoverImages = async () => {
    const images = {};
    for (const course of filteredData) {
      try {
        console.log(`Fetching image for course ${course.id}`);
        const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
        console.log(`Received response for course ${course.id}:`, response.data);

        if (response.data.dataUrl) {
          const imageExists = await checkImageExists(response.data.dataUrl);
          if (imageExists) {
            images[course.id] = response.data.dataUrl;
          } else {
            console.log(`Image for course ${course.id} does not exist in S3 bucket`);
            images[course.id] = null; // or a default image URL
          }
        } else {
          console.log(`No image URL received for course ${course.id}`);
          images[course.id] = null; // or a default image URL
        }
      } catch (error) {
        console.error(`Error fetching cover image for course ${course.id}:`, error);
        images[course.id] = null; // or a default image URL
      }
    }
    console.log("All fetched images:", images);
    setCoverImages(images);
  };


  useEffect(() => {
    fetchCoverImages();
  }, [filteredData]);


  function checkImageExists(imageUrl) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
      image.src = imageUrl;
    });
  }

  function formatClassId(classId) {
    // Check if classId is a string, if not, convert it to a string
    const classIdString = typeof classId === 'string' ? classId : String(classId);

    // Use regex to match all sequences of digits, sort them, and join them with commas and spaces
    const sortedClassIds = classIdString.match(/\d+/g)?.map(Number).sort((a, b) => a - b).join(', ') || '';

    return sortedClassIds;
  }


  function formatTags(tag) {
    if (!tag || !Array.isArray(tag) || tag.length === 0) {
      return 'No tags available'; // Handle case where tag is not an array, is empty, or has no elements
    }

    // Join the array elements into a comma-separated string
    return tag.join(', ');
  }

  // Function to handle importing a cours

  return (
    <>
      <div className='mx-4 border-b border-gray-200'>
        <div className='pt-2 pb-3 flex justify-between'>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900 font-bold cursor-pointer">
              Store
            </p>
          </div>
          <div className='flex flex-row justify-between flex-x-2'>
            <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
              <div className="grid place-items-center w-12 text-gray-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Search Course Name"
                onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </div>

      </div>

      <div className=" py-6 mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">
        {filteredData.map((course, index) => (

          <div className='bg-white rounded-md border-solid border border-gray-200 p-4 h-max max-w-md'>
            <div className="flex justify-between">

              <h1 className="mb-4 text-md font-semibold text-gray-800">
                {truncateText(course.course_name, 25)}
              </h1>
              <div x-data="{ isOpen: true }" className="relative inline-block ">
                <button
                  onClick={() => toggleDropdown(course.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 font-bold">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                  </svg>

                </button>

                {dropdownState[course.id] && (
                  <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl"
                  >
                    <Link to={`/store-view/${course.id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      </svg>


                      <span className="mx-1">
                        View Course
                      </span>
                    </Link>
                  </div>
                )}

              </div>
            </div>

            {/* <img className="object-cover object-center w-full h-64 rounded-lg lg:h-40" src={coverPhoto} alt="" /> */}

            {coverImages[course.id] ? (
              <img
                src={coverImages[course.id]}
                alt={`${course.course_name} cover`}
                className="object-cover object-center w-full h-64 rounded-lg lg:h-40 transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
                onError={(e) => {
                  console.error(`Error loading image for course ${course.id}:`, e);
                  e.target.src = 'path/to/fallback/image.jpg';
                }}
              />
            ) : (
              <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
                <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
              </div>
            )}

            <div className="mt-4">

              <p className="mt-2 text-gray-500 h-12 text-sm">
                {truncateText(course.course_desc, 65)}
              </p>

              {/* <div className="flex flex-row justify-between w-full max-w-xs gap-1 mt-3 text-center">
                <span className="bg-blue-100 flex items-center text-gray-700 justify-center  text-xs rounded-lg py-2 px-3">
                  Grade : 2,4,5
                </span>
                <span className="bg-blue-100 flex items-center text-gray-700 justify-center  text-xs rounded-lg py-2 px-3 w-2/6">
                  {course.difficulty_level}
                </span>
                <span className="bg-blue-100 flex items-center text-gray-700 justify-center  text-xs rounded-lg py-2 px-3">
                  Vowels
                </span>
              </div> */}

              <div className="flex flex-col w-full gap-2 mt-1 rounded-sm">
                <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                  <span className="font-semibold mr-1">Grade :</span>  {formatClassId(course.class_id)}
                </span>
                <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                  <span className="font-semibold mr-1">Difficulty Level :</span>
                  <span className="capitalize">{course.difficulty_level}</span>
                </span>
                <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                  <span className="font-semibold mr-1">Tags :</span>  {formatTags(course.tags)}
                </span>
              </div>


              <div className='flex flex-row gap-2 justify-between px-1 flex-wrap mt-3'>
                <div className="flex flex-col items-start mt-4">
                  <p className="text-xs text-gray-500">{truncateText(`${course.first_name} ${course.last_name}`, 14)}</p>
                  {/* <p className="text-xs text-gray-500">{course.first_name} {course.last_name}  {truncateText(course.course_name, 25)}</p> */}
                  <p className="text-sm font-medium text-gray-700 cursor-pointer">
                    Creator Name
                  </p>
                </div>
                <div className="flex flex-col items-start mt-4">
                  <p className="text-xs text-gray-500">{course.slide_count ? course.slide_count : 0}</p>
                  <p className="text-sm font-medium text-gray-700 cursor-pointer">
                    Slides
                  </p>
                </div>
                <div className="flex flex-col items-start mt-4">
                  <p className="text-xs text-gray-500">{course.test_count ? course.test_count : 0}</p>
                  <p className="text-sm font-medium text-gray-700 cursor-pointer">
                    Tests
                  </p>
                </div>

                <div className="text-center flex items-center mt-4  rounded-full">

                  <button type="button" className="inline-flex truncate gap-2 justify-center items-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded-full" onClick={() => importCourse(course.id)}>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-xs font-bold">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>

                  </button>
                </div>
              </div>

            </div>
          </div>


        ))}

      </div>
    </>
  )
}

export default Store;
