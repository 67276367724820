import { BASEURL } from '../../../constants';
import Footer from './Footer';
import Slide from "./Slide";
import SlideView from "./SlideView";
import TopicSubTopicView from "./TopicSubTopicView";
import {useState , useRef} from 'react';

const SlideConsumption = ({ currentSlideData, topicName, subtopicName, slidesArray, currentSlideId,

    setCurrentSlideId, setCurrentSlideData, setStartTime,markFinish,

     setPrevious, setSelectedSubtopicId, setSelectedTestId
}) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    let currentUtterance = useRef(null);

    function nextSlide() {
        //[{id}{id}{id}]
        const currentSlideIndex = slidesArray.findIndex((slide) => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex + 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex + 1]);
        const now = new Date();
        

        setStartTime(new Date().toISOString().slice(0, 19).replace('T', ' '))
    }

    function previousSlide() {
        const currentSlideIndex = slidesArray.findIndex(slide => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex - 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex - 1]);
        setStartTime(new Date().toISOString().slice(0, 19).replace('T', ' '))
    }

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentSlideId === slidesArray[slidesArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentSlideId === slidesArray[0].id) return disableStyle;
        return {}
    }
    //slidesArray [ {} ]

    function chunkText(text, maxChunkSize = 150) {
        const chunks = [];
        let start = 0;
        while (start < text.length) {
            let end = start + maxChunkSize;
            if (end < text.length) {
                // Ensure we don't split a word by finding the last space within the chunk
                while (end > start && text[end] !== ' ') {
                    end--;
                }
                if (end === start) {
                    end = start + maxChunkSize; // No space found, force split at maxChunkSize
                }
            }
            chunks.push(text.slice(start, end).trim());
            start = end + 1; // Skip the space at the split point
        }
        return chunks;
    }

    function speakChunks(chunks, index = 0) {
        if (index < chunks.length) {
            const utterance = new SpeechSynthesisUtterance(chunks[index]);
            utterance.lang = 'hi-IN';
            utterance.onend = () => {
                console.log('Utterance finished:', chunks[index]);
                speakChunks(chunks, index + 1);
                if (index === (chunks.length - 1)) {
                    setIsSpeaking(false)
                }
            };
            utterance.onerror = (event) => {
                console.error('SpeechSynthesisUtterance.onerror', event);
            };
            console.log('Speaking chunk:', chunks[index]);
            currentUtterance = utterance;
            window.speechSynthesis.speak(utterance);
        }
    }

   
    function handleTextToSpeech() {
        //we have currentSlideData
        let text = '';
        currentSlideData.content.forEach(element => {
            if (element.type === 'Heading') {
                text += element.data;
            }
            if (element.type === 'Text') {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = element.data;
                const t = tempDiv.textContent;
                text += t;
            }
        });
        console.log('text', text);
        if (text) {
            setIsSpeaking(true);
            const chunks = chunkText(text);
            speechSynthesis.cancel();
            speakChunks(chunks);
        } else {
            alert('No text in the slide')
        }

    }


    function handleSpeechStop() {
        if (currentUtterance) {
            window.speechSynthesis.cancel(); // Cancel the current utterance
            setIsSpeaking(false); // Update the speaking state
            currentUtterance = null;
        }
    }

    // function disableJumpNext() {
    //     //contentArray 
    //     //contentIndex
    //     if ((contentArray.length - 1) === contentIndex) {
    //         //on the last contentObject disable it
    //         return disableStyle;
    //     } else {
    //         return {}
    //     }
    // }

    // function disableJumpBack() {
    //     if (contentIndex === 0) {
    //         return disableStyle;
    //     } else {
    //         return {}
    //     }
    // }

    // function nextContent() {
    //     console.log('contentArray', contentArray);
    //     const nextContent = contentArray[contentIndex + 1];
    //     console.log('next content', nextContent, contentArray, contentIndex);
    //     if (nextContent.type === 'slide-group') {
    //         setSelectedSubtopicId(nextContent.id);
    //         setSelectedTestId(null);
    //         setContentIndex(contentIndex + 1)
    //     }
    //     if (nextContent.type === 'test') {
    //         console.log('setting test id')

    //         setSelectedTestId(nextContent.id);
    //         setSelectedSubtopicId(null);
    //         setContentIndex(contentIndex + 1)
    //     }
    // }

    // function previousContent() {
    //     const previousContent = contentArray[contentIndex - 1];
    //     if (previousContent.type === 'slide-group') {
    //         setPrevious(true)
    //         setSelectedSubtopicId(previousContent.id);
    //         setSelectedTestId(null);
    //         setContentIndex(contentIndex - 1)
    //     }
    //     if (previousContent.type === 'test') {
    //         setPrevious(true)
    //         setSelectedTestId(previousContent.id);
    //         setSelectedSubtopicId(null);
    //         setContentIndex(contentIndex - 1)
    //     }
    // }

    

    return (
        <div className="h-full flex flex-col 2xl:w-5/6 w-4/6 grow">
            <TopicSubTopicView nameOne={topicName} nameTwo={subtopicName} />
            <SlideView slidesArray={slidesArray} currentSlideId={currentSlideId} />
            <Slide currentSlideData={currentSlideData} />
            <Footer
                handleSpeechStop={handleSpeechStop}
                handleTextToSpeech={handleTextToSpeech}
                // disableJumpBack={disableJumpBack}
                disablePrevious={disablePrevious}
                // disableJumpNext={disableJumpNext}
                disableNext={disableNext}
                // previousContent={previousContent}
                previousSlide={previousSlide}
                // nextContent={nextContent}
                nextSlide={nextSlide}
                isSpeaking={isSpeaking}
                slidesArray={slidesArray}
                currentSlideId={currentSlideId}
                markFinish={markFinish}
                currentSlideData={currentSlideData}
            />
        </div>
    );
}

export default SlideConsumption;