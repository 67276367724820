const TestInfoView = ({ questionsArray, currentQuestionId }) => {


    return (
        <div className="flex bg-white gap-2 p-3 items-center">
            {
                questionsArray.map((question, index) => {
                    let styleObj = { backgroundColor: '#726DB0' }

                    
                    if (question.id === currentQuestionId) {
                        styleObj.backgroundColor = '#fe7d5f';
                    }
                    
                    if (question.attempted) {
                        styleObj.backgroundColor = '#ca634c';
                    }

                    
                    return (
                        <div key={index} className="w-8 h-1 rounded-full" style={styleObj} >

                        </div>
                    )
                })
            }
        </div>
    );
}

export default TestInfoView;