import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../../constants";

const DiactoEditMentor = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    // Add a new state variable to store the selected mentor
    const [schools, setSchools] = useState([]);
    // const [classes, setClasses] = useState([]);



    const [formData, setFormData] = useState({
        // Initial form state
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        birthdate: "",
        email: "",
        contactNumber: "",
        alternativeContactNumber: "",
        aadharcardNumber: "",
        permanentAddress: "",
        city: "",
        state: "",
        zipCode: "",
        schoolId: "",

    });

    useEffect(() => {
        // Fetch mentor data based on id when component mounts
        axios
            .get(`${BASEURL}/api/fetch-mentor-details/${userId}`)
            .then((res) => {
                const fetchedMentorDetails = res.data.mentorDetails || {};
                console.log("Fetched Mentor Data:", fetchedMentorDetails);
                const mentor = {
                    userId: userId,
                    firstName: fetchedMentorDetails.first_name,
                    middleName: fetchedMentorDetails.middle_name,
                    lastName: fetchedMentorDetails.last_name,
                    email: fetchedMentorDetails.email,
                    gender: fetchedMentorDetails.gender,
                    birthdate: fetchedMentorDetails.birthdate,
                    contactNumber: fetchedMentorDetails.contact_number,
                    alternativeContactNumber:
                    fetchedMentorDetails.alternative_number,
                    permanentAddress: fetchedMentorDetails.address,
                    city: fetchedMentorDetails.city,
                    state: fetchedMentorDetails.state,
                    zipCode: fetchedMentorDetails.zip_code,
                    schoolId: fetchedMentorDetails.org_id,
                };
                setFormData(mentor);
            })
            .catch((error) => {
                console.error("Error fetching mentor data:", error);
            });
    }, []);

    const notify = () => toast.success("Successfully updated !!");

    useEffect(() => {
        const fetchschools = async () => {
            try {
                const response = await axios.get(`${BASEURL}/api/get-schools`);
                setSchools(response.data.organisation);
            } catch (error) {
                console.error("Error fetching schools:", error.message);
            }
        };

        fetchschools();
    }, []);


    const handleSchoolChange = (e) => {
        const { value } = e.target;
        console.log("VALUE", value);

        setFormData((prevData) => ({
            ...prevData,
            schoolId: value,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("VALUE", value);
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateField = (value, expectedLength) => {
        // Ensure value is a string
        const stringValue = String(value);
        // Remove any non-digit characters and trim
        const cleanedValue = stringValue.replace(/\D/g, '').trim();
        return cleanedValue.length === expectedLength ? cleanedValue : null;
    };


    const handleUpdateMentor = (e) => {
        e.preventDefault();

        const processedSchoolData = {
            ...formData,
            // Only apply trim() if alternativeNumber is a truthy string, otherwise set to null

            alternativeNumber:
                typeof formData.alternativeNumber === "string" &&
                    formData.alternativeNumber.trim() !== ""
                    ? formData.alternativeNumber.trim()
                    : null,

            middleName:
                typeof formData.middleName === "string" &&
                    formData.middleName.trim() !== ""
                    ? formData.middleName.trim()
                    : null,
        };

        const requiredFields = [
            "firstName",
            // "middleName",
            "lastName",
            "gender",
            "email",
            // "aadharcardNumber",
            "birthdate",
            "contactNumber",
            // "alternativeContactNumber",
            "permanentAddress",
            "city",
            "state",
            "zipCode",
        ];

        // Validate contact numbers for exactly 10 digits
        const contactFields = ["contactNumber", "alternativeContactNumber"];
        for (const field of contactFields) {
            if (formData[field]) {
                const validatedValue = validateField(String(formData[field]), 10);
                if (!validatedValue) {
                    toast.error(
                        `${field.replace(/([A-Z])/g, " $1")} must be exactly 10 digits`,
                        { duration: 4000 }
                    );
                    return;
                }
                formData[field] = validatedValue;
            }
        }

        // Validate Aadhar card number for exactly 12 digits
        // if (formData["aadharcardNumber"]) {
        //     const validatedAadhar = validateField(String(formData["aadharcardNumber"]), 12);
        //     if (!validatedAadhar) {
        //         toast.error("Aadhar Card Number must be exactly 12 digits", {
        //             duration: 4000,
        //         });
        //         return;
        //     }
        //     formData["aadharcardNumber"] = validatedAadhar;
        // }


        let isValid = true;
        for (const field of requiredFields) {
            if (!processedSchoolData[field]) {
                toast.error(
                    `${field
                        .replace(/([A-Z])/g, " $1")
                        .charAt(0)
                        .toUpperCase() + field.slice(1)
                    }: Field is required`,
                    {
                        duration: 4000,
                    }
                );
                isValid = false;
                break; // Stop checking once a missing field is found
            }
        }

        // If not all required fields are filled, stop the submission
        if (!isValid) {
            return;
        }

        toast.dismiss();

        axios
            .put(`${BASEURL}/api/update-mentor`, formData)
            .then((response) => {
                console.log(response.data.message);
                notify();
                navigate("/diactoAdmin/allMentors");
            })
            .catch((error) => {
                console.error("Error updating mentor:", error);
                toast.error("Error updating mentor. Please try again.", {
                    duration: 4000,
                });
            });
    };


    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Update Mentor Profile
                        </p>
                    </div>

                    <div class="flex items-center justify-end gap-x-4">
                        <Link to={`/diactoAdmin/allMentors`}>
                            <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
                        </Link>

                        <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleUpdateMentor}>Save</button>
                    </div>
                </div>
            </div>

            <form>
                <div class="space-y-12 px-4 mx-auto my-0">


                    <div class="border-b border-gray-900/10 pb-12">
                        <h2 class="text-md font-semibold leading-7 text-gray-900 mt-4  pl-2 my-2 border-l-4 font-sans border-blue-400">Personal Information</h2>

                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="sm:col-span-2 sm:col-start-1">
                                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First Name <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="firstName" placeholder="Enter your first name" value={formData.firstName} onChange={handleChange} required autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="middle-name" class="block text-sm font-medium leading-6 text-gray-900">Middle Name</label>
                                <div class="mt-2">
                                    <input type="text" name="middleName" placeholder="Enter your middle name" value={formData.middleName} onChange={handleChange} required autocomplete="additional-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last Name <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your last name" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <select name="gender" value={formData.gender} onChange={handleChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                        <option value='Others'>Others</option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="dob" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="birthdate" type="date" value={formData.birthdate} onChange={handleChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="email" type="email" value={formData.email} onChange={handleChange} required autocomplete="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your email address" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="contact-number" class="block text-sm font-medium leading-6 text-gray-900">Contact Number <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="contactNumber" maxLength={10} value={formData.contactNumber} onChange={handleChange} required type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your contact number" />
                                </div>
                            </div>
{/* 
                            <div class="sm:col-span-2">
                                <label for="alt-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Alternate Contact Number</label>
                                <div class="mt-2">
                                    <input name="alternativeContactNumber" maxLength={10} value={formData.alternativeContactNumber} onChange={handleChange} type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your alternate contact number" />
                                </div>
                            </div> */}

                            <div class="sm:col-span-2">
                                <label for="aadhar-card-number" class="block text-sm font-medium leading-6 text-gray-900">Aadhar Card Number <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="aadharcardNumber" maxLength={12} value={formData.aadharcardNumber} onChange={handleChange} required type="tel" autocomplete="on" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your Aadhar card number" />
                                </div>
                            </div>


                            {/* <div class="sm:col-span-2">
                                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Select School <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <select name="schoolName" value={formData.schoolId} onChange={handleSchoolChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                                        <option value="" disabled selected>
                                            Select school
                                        </option>
                                        {schools.map((school) => (
                                            <option key={school.id} value={school.id}>
                                                {school.school_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div> */}

<div class="sm:col-span-2">
                                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Select School <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <select
                                        name="schoolId"
                                        value={formData.schoolId}
                                        onChange={handleChange}
                                        required
                                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                    >
                                        <option value="" disabled>Select school</option>
                                        {schools.map((school) => (
                                            <option key={school.id} value={school.id}>
                                                {school.organisation_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* 
                            <div class="sm:col-span-2">
                                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Profile Image <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="file"
                                        accept="image/*"
                                        onChange={handleFileChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div> */}

                        </div>

                        <h2 class="text-md font-semibold leading-7 text-gray-900 mt-6  pl-2 my-2 border-l-4 font-sans border-blue-400">Address Details</h2>

                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="col-span-full">
                                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Permanent Address <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="permanentAddress" value={formData.permanentAddress} onChange={handleChange} required autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your permanent address" />
                                </div>
                            </div>

                            <div class="sm:col-span-2 sm:col-start-1">
                                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="city" value={formData.city} onChange={handleChange} required autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your city" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="region" class="block text-sm font-medium leading-6 text-gray-900">State <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="state" value={formData.state} onChange={handleChange} required autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your State" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="zipCode" maxLength={6} value={formData.zipCode} onChange={handleChange} required autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your ZIP code" />
                                </div>
                            </div>

                        </div>

                    </div>



                    {/* ----------------------------------------------------- */}


                </div>

                {/* <div class="my-6 flex items-center justify-end gap-x-4">
                    <Link to={`/diactoAdmin/allMentors`}>
                        <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
                    </Link>

                    <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleUpdateMentor}>Save</button>
                </div> */}
            </form>
        </>
    )
}

export default DiactoEditMentor