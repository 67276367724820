

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../../constants";

const AdminEditTeacher = () => {
    const navigate = useNavigate();
    const { schoolId, userId } = useParams();

    const [formData, setFormData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        birthdate: "",
        email: "",
        contactNumber: "",
        alternativeNumber: "",
        // aadharCardNumber: "",
        // panCard: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        emergencyContactName: "",
        emergencyContactNumber: "",
    });

    useEffect(() => {
        axios
            .get(`${BASEURL}/api/fetch-creator-details/${schoolId}/${userId}`)
            .then((res) => {
                const fetchedCreatorDetails = res.data.creatorDetails || {};
                console.log("Fetched Teacher Data:", fetchedCreatorDetails);
                const teacher = {
                    schoolId: schoolId,
                    userId: userId,
                    firstName: fetchedCreatorDetails.first_name,
                    middleName: fetchedCreatorDetails.middle_name,
                    lastName: fetchedCreatorDetails.last_name,
                    gender: fetchedCreatorDetails.gender,
                    birthdate: fetchedCreatorDetails.birthdate,
                    email: fetchedCreatorDetails.email,
                    contactNumber: fetchedCreatorDetails.contact_number,
                    alternativeNumber: fetchedCreatorDetails.alternative_number,
                    // aadharCardNumber: fetchedCreatorDetails.aadharcard_number,
                    // panCard: fetchedCreatorDetails.pan_card,
                    address: fetchedCreatorDetails.address,
                    city: fetchedCreatorDetails.city,
                    state: fetchedCreatorDetails.state,
                    zipCode: fetchedCreatorDetails.zip_code,
                    emergencyContactName: fetchedCreatorDetails.emergency_contact_name,
                    emergencyContactNumber: fetchedCreatorDetails.emergency_contact_number,
                };
                setFormData(teacher);
            })
            .catch((error) => {
                console.error("Error fetching teacher data:", error);
            });
    }, [schoolId, userId]);

    const notify = () => toast.success("Creator Profile updated successfully!");

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        // For numeric fields, only allow digits
        if (['contactNumber', 'alternativeNumber', 'emergencyContactNumber', 'aadharCardNumber', 'zipCode'].includes(name)) {
            newValue = value.replace(/\D/g, '');
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    const validateField = (value, expectedLength) => {
        // Ensure value is a string
        const stringValue = String(value);
        // Remove any non-digit characters and trim
        const cleanedValue = stringValue.replace(/\D/g, '').trim();
        return cleanedValue.length === expectedLength ? cleanedValue : null;
    };

    const handleUpdateTeacher = (e) => {
        e.preventDefault();

        // Ensure alternativeNumber and middleName are treated as strings
        const processedFormData = {
            ...formData,
            alternativeNumber: formData.alternativeNumber ? String(formData.alternativeNumber).trim() || null : null,
            middleName: formData.middleName ? String(formData.middleName).trim() || null : null,
        };

        // Validation for required fields
        const requiredFields = [
            "firstName",
            "lastName",
            "gender",
            "birthdate",
            "email",
            "contactNumber",
            // "aadharCardNumber",
            // "panCard",
            "address",
            "city",
            "state",
            "zipCode",
            "emergencyContactName",
            "emergencyContactNumber",
        ];

        // Validate contact numbers for exactly 10 digits
        const contactFields = [
            "contactNumber",
            "alternativeNumber",
            "emergencyContactNumber",
        ];
        for (const field of contactFields) {
            if (processedFormData[field]) {
                const validatedValue = validateField(String(processedFormData[field]), 10);
                if (!validatedValue) {
                    toast.error(
                        `${field.replace(/([A-Z])/g, " $1")} must be exactly 10 digits`,
                        { duration: 4000 }
                    );
                    return;
                }
                processedFormData[field] = validatedValue;
            }
        }

        // Validate Aadhar card number for exactly 12 digits
        // if (processedFormData["aadharCardNumber"]) {
        //     const validatedAadhar = validateField(String(processedFormData["aadharCardNumber"]), 12);
        //     if (!validatedAadhar) {
        //         toast.error("Aadhar Card Number must be exactly 12 digits", {
        //             duration: 4000,
        //         });
        //         return;
        //     }
        //     processedFormData["aadharCardNumber"] = validatedAadhar;
        // }

        let isValid = true;
        for (const field of requiredFields) {
            if (!processedFormData[field]) {
                toast.error(
                    `${field
                        .replace(/([A-Z])/g, " $1")
                        .charAt(0)
                        .toUpperCase() + field.slice(1)
                    }: Field is required`,
                    {
                        duration: 4000,
                    }
                );
                isValid = false;
                break;
            }
        }

        if (!isValid) {
            return;
        }

        axios
            .put(`${BASEURL}/api/update-creator`, processedFormData)
            .then((response) => {
                console.log(response.data.message);
                notify();
                navigate(`/admin/allCreators/${schoolId}`);
            })
            .catch((error) => {
                console.error(
                    "Error updating creator profile:",
                    error.response ? error.response.data.error : "Network Error"
                );
                toast.error("Error updating creator profile. Please try again.", {
                    duration: 4000,
                });
            });
    };

    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Update Creator Information
                        </p>
                    </div>
                    <div className="flex items-center justify-end gap-x-4 px-4">
                        <Link to={`/admin/allCreators/${schoolId}`}>
                            <button type="button" className="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Cancel</button>
                        </Link>

                        <button type="submit" onClick={handleUpdateTeacher} className="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Save</button>
                    </div>
                </div>
            </div>

            <form onSubmit={handleUpdateTeacher}>
                <div className="space-y-12 px-4 mx-auto my-0">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-md font-semibold leading-7 text-gray-900 mt-4 pl-2 my-2 border-l-4 font-sans border-blue-400">Personal Information</h2>

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">First Name <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your first name"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="middle-name" className="block text-sm font-medium leading-6 text-gray-900">Middle Name</label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="middleName"
                                        value={formData.middleName}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your middle name"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">Last Name <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your last name"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="gender" className="block text-sm font-medium leading-6 text-gray-900">Gender <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <select
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                    >
                                        <option value="" disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="birthdate" className="block text-sm font-medium leading-6 text-gray-900" >Date of Birth <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="date"
                                        name="birthdate"
                                        value={formData.birthdate}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="contact-number" className="block text-sm font-medium leading-6 text-gray-900">Contact Number <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="tel"
                                        name="contactNumber"
                                        value={formData.contactNumber}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your contact number" maxLength={10}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="alt-contact-number" className="block text-sm font-medium leading-6 text-gray-900">Alternate Contact Number</label>
                                <div className="mt-2">
                                    <input
                                        type="tel"
                                        name="alternativeNumber"
                                        value={formData.alternativeNumber}
                                        onChange={handleChange}
                                        maxLength={10}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-insetfocus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your alternate contact number"
                                    />
                                </div>
                            </div>
                            {
                                /* 
                                <div className="sm:col-span-2">
                                <label htmlFor="aadhar-card-number" className="block text-sm font-medium leading-6 text-gray-900">Aadhar Card Number <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="tel"
                                        name="aadharCardNumber"
                                        value={formData.aadharCardNumber}
                                        onChange={handleChange}
                                        required
                                        maxLength={12}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your Aadhar card number"
                                    />
                                </div>
                            </div>
                                */
                            }


                            {/* <div className="sm:col-span-2">
                                <label htmlFor="pancard-number" className="block text-sm font-medium leading-6 text-gray-900">Pancard Number <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="panCard"
                                        value={formData.panCard}
                                        onChange={handleChange}
                                        required
                                        maxLength={10}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your PAN card number"
                                    />
                                </div>
                            </div> */}
                        </div>

                        <h2 className="text-md font-semibold leading-7 text-gray-900 mt-6 pl-2 my-2 border-l-4 font-sans border-blue-400">Address Details</h2>

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">Permanent Address <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your permanent address"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">City <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your city"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">State <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your State"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="zipCode"
                                        value={formData.zipCode}
                                        onChange={handleChange}
                                        required
                                        maxLength={6}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter your ZIP code"
                                    />
                                </div>
                            </div>
                        </div>

                        <h2 className="text-md font-semibold leading-7 text-gray-900 mt-6 pl-2 my-2 border-l-4 font-sans border-blue-400">Other Details</h2>

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3 sm:col-start-1">
                                <label htmlFor="emergency-contact-name" className="block text-sm font-medium leading-6 text-gray-900">Emergency Contact Name <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="emergencyContactName"
                                        value={formData.emergencyContactName}
                                        onChange={handleChange}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter emergency contact name"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="emergency-contact-number" className="block text-sm font-medium leading-6 text-gray-900">Emergency Contact Number <span className="text-red-600">*</span></label>
                                <div className="mt-2">
                                    <input
                                        type="tel"
                                        name="emergencyContactNumber"
                                        value={formData.emergencyContactNumber}
                                        onChange={handleChange}
                                        required
                                        maxLength={10}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6"
                                        placeholder="Enter emergency contact number"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="my-6 flex items-center justify-end gap-x-4 px-4">
                    <Link to={`/admin/allTeachers/${schoolId}`}>
                        <button type="button" className="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Cancel</button>
                    </Link>

                    <button type="submit" className="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500">Save</button>
                </div> */}
            </form>
            <Toaster />
        </>
    );
};

export default AdminEditTeacher;