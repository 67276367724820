export function formatClassId(classId) {
    // Check if classId is a string, if not, convert it to a string
    const classIdString = typeof classId === 'string' ? classId : String(classId);

    // Use regex to match all sequences of digits, sort them, and join them with commas and spaces
    const sortedClassIds = classIdString.match(/\d+/g)?.map(Number).sort((a, b) => a - b).join(', ') || '';

    return sortedClassIds;
}


export function formatTags(tag) {
    if (!tag || !Array.isArray(tag) || tag.length === 0) {
        return 'No tags available'; // Handle case where tag is not an array, is empty, or has no elements
    }

    // Join the array elements into a comma-separated string
    return tag.join(', ');
}

export function checkImageExists(imageUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(true);
        image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
        image.src = imageUrl;
    });
}