// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import toast from "react-hot-toast";
// import Image from '../assets/login_background.png'

// import { BASEURL } from "../../constants";
// import { jwtDecode } from "jwt-decode";


// const AdminLogin = () => {

//     const [credentials, setCredentials] = useState({
//         email: "",
//         password: "",
//     });
//     const [error, setError] = useState("");
//     const [showPassword, setShowPassword] = useState(false);
//     const navigate = useNavigate();

//     // useEffect for Axios interceptor logic
//     useEffect(() => {
//         const requestInterceptor = axios.interceptors.request.use(
//             (config) => {
//                 // Add configurations here
//                 const token = localStorage.getItem("auth");
//                 if (token) {
//                     config.headers.Authorization = `Bearer ${token}`;
//                 }
//                 return config;
//             },
//             (err) => {
//                 return Promise.reject(err);
//             }
//         );

//         return () => {
//             // Cleanup the request interceptor
//             axios.interceptors.request.eject(requestInterceptor);
//         };
//     }, []);

//     const handleAdminLogin = (event) => {
//         event.preventDefault();

//         axios.post(`${BASEURL}/adminLogin`, credentials)
//             .then((response) => {
//                 const { data } = response;
//                 if (data.success) {
//                     localStorage.setItem("auth", data.token); // Store the token in local storage

//                     // Decode the token to get the user's role and IDs
//                     const decoded = jwtDecode(data.token);
//                     const userRole = decoded.role;
//                     const user_id = decoded.user_id;
//                     const school_id = decoded.school_id;

//                     // Output user and school ID for debugging purposes (consider removing for production)
//                     console.log('userId', user_id);
//                     console.log('schoolId', school_id);

//                     // Navigate based on role and decoded data
//                     if (userRole === 'superadmin') {
//                         navigate("/diactoAdmin/home");
//                     } else if (user_id && school_id) {
//                         navigate(`/admin/home/${user_id}/${school_id}`);
//                     } else {
//                         toast.error("Authentication successful, but no user or school information found.");
//                     }
//                 } else {
//                     toast.error('Email or password is incorrect');
//                 }
//             })
//             .catch((error) => {
//                 console.error("Login Error:", error);
//                 toast.error('Login failed. Please check your credentials.');
//             });
//     };


//     const handleTogglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };

//     return (
//         <div>

//             <div className="bg-white dark:bg-gray-900">
//                 <div className="flex justify-center h-screen">

//                     <div class="lg:w-3/5 bg-black h-full hidden lg:block ">
//                         <img class="object-cover w-full h-full bg-gray-900 bg-opacity-30" src={Image} alt="Side" />
//                     </div>
//                     <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/5">
//                         <div className="flex-1">
//                             <div className="text-center">
//                                 <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">BlissIQ</h2>

//                                 <h3 className="mt-3 text-2xl text-blue-500 dark:text-gray-300">Admin Portal</h3>
//                                 <p className="mt-3 text-gray-500 dark:text-gray-300">Log in to access your account</p>
//                             </div>

//                             <div className="mt-8">
//                                 <form>
//                                     <div>
//                                         <label for="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">User Email</label>

//                                         <input type="text"
//                                             value={credentials.email}
//                                             onChange={(e) =>
//                                                 setCredentials({
//                                                     ...credentials,
//                                                     email: e.target.value,
//                                                 })
//                                             } placeholder="Ex: john.doe@gmail.com" className="block w-full px-4 py-3 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
//                                     </div>

//                                     <div className="mt-6">
//                                         <div className="flex justify-between mb-2">
//                                             <label for="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
//                                             <Link to="/forgotPassword" className="text-sm text-gray-400 text-blue-500 focus:outline-none hover:text-red-500">Forgot password?</Link>
//                                         </div>

//                                         <input type={showPassword ? "text" : "password"}
//                                             value={credentials.password}
//                                             placeholder="password"
//                                             onChange={(e) =>
//                                                 setCredentials({
//                                                     ...credentials,
//                                                     password: e.target.value,
//                                                 })
//                                             } className="block w-full px-4 py-3 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
//                                     </div>

//                                     <div className="mt-6">
//                                         <button onClick={handleAdminLogin}
//                                             className="w-full px-4 py-3 my-3 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
//                                             Log in
//                                         </button>
//                                     </div>

//                                 </form>

//                                 <p className="mt-6 text-sm text-center text-gray-400">
//                                     <Link to="/login" className="text-blue-500 focus:outline-none hover:text-red-500">Log in as Creator or Student</Link>
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default AdminLogin


import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Image from '../assets/login_background.png';
import { BASEURL } from "../../constants";
import {jwtDecode} from "jwt-decode";

const AdminLogin = () => {
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("auth");
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (err) => Promise.reject(err)
        );

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);

    const handleAdminLogin = (event) => {
        event.preventDefault();

        axios.post(`${BASEURL}/login`, credentials)
            .then((response) => {
                const { data } = response;
                if (data.success) {
                    localStorage.setItem("auth", data.token);

                    const decoded = jwtDecode(data.token);
                    console.log("decoded " , decoded)
                    const userRole = decoded.role;
                    const user_id = decoded.user_id;
                    const school_id = decoded.org_id;

                    console.log('userId', user_id);
                    console.log('schoolId', school_id);

                    if (userRole === 'superadmin') {
                        navigate("/diactoAdmin/home");
                    } else if (user_id && school_id) {
                        navigate(`/admin/home/${user_id}/${school_id}`);
                    } else {
                        toast.error("Authentication successful, but no user or school information found.");
                    }
                } else {
                    toast.error('Email or password is incorrect');
                }
            })
            .catch((error) => {
                console.error("Login Error:", error);
                toast.error('Login failed. Please check your credentials.');
            });
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="bg-white dark:bg-gray-900">
            <div className="flex justify-center h-screen">
                <div className="lg:w-3/5 bg-black h-full hidden lg:block">
                    <img className="object-cover w-full h-full bg-gray-900 bg-opacity-30" src={Image} alt="Side" />
                </div>
                <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/5">
                    <div className="flex-1">
                        <div className="text-center">
                            <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">BlissIQ</h2>
                            <h3 className="mt-3 text-2xl text-blue-500 dark:text-gray-300">Admin Portal</h3>
                            <p className="mt-3 text-gray-500 dark:text-gray-300">Log in to access your account</p>
                        </div>
                        <div className="mt-8">
                            <form>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">User Email</label>
                                    <input 
                                        type="text"
                                        value={credentials.email}
                                        onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                                        placeholder="Ex: john.doe@gmail.com"
                                        className="block w-full px-4 py-3 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                                </div>
                                <div className="mt-6">
                                    <div className="flex justify-between mb-2">
                                        <label htmlFor="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                        <Link to="/forgotPassword" className="text-sm text-gray-400 text-blue-500 focus:outline-none hover:text-red-500">Forgot password?</Link>
                                    </div>
                                    <input 
                                        type={showPassword ? "text" : "password"}
                                        value={credentials.password}
                                        placeholder="password"
                                        onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                                        className="block w-full px-4 py-3 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                    />
                                </div>
                                <div className="mt-6">
                                    <button 
                                        onClick={handleAdminLogin}
                                        className="w-full px-4 py-3 my-3 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                                    >
                                        Log in
                                    </button>
                                </div>
                            </form>
                            <p className="mt-6 text-sm text-center text-gray-400">
                                <Link to="/login" className="text-blue-500 focus:outline-none hover:text-red-500">Log in as Creator or Student</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
