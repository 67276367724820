import { useState } from "react";
import PreviewTestThumbnail from "./PreviewTestThumbnail";

const NewPreviewTestComponent = ({ contentArray, setContentIndex, setPrevious,
    contentIndex, setSelectedChapterId,
    setSelectedTestId, testName, selectedTestId, question, setQuestion, questionsArray, setQuestionsArray, setIsDataSaved, setImagesArray, setCurrentQuestionId, currentQuestionId }) => {

    const [isStarted, setIsStarted] = useState(true);

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disablePrevious() {
        if (questionsArray[0].id === currentQuestionId) {
            return disableStyle;
        }
    }

    function disableNext() {
        if (questionsArray[questionsArray.length - 1].id === currentQuestionId) {
            return disableStyle
        }
    }

    function previousQuestion() {
        const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
        const previousQuestion = questionsArray[currentQuestionsIndex - 1];
        setQuestion(previousQuestion);
        setCurrentQuestionId(previousQuestion.id)
    }

    function nextQuestion() {
        const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
        const nextQuestion = questionsArray[currentQuestionsIndex + 1];
        setQuestion(nextQuestion);
        setCurrentQuestionId(nextQuestion.id)
    }


    function disableJumpNext() {
        //contentArray 
        //contentIndex
        if ((contentArray.length - 1) === contentIndex) {
            //on the last contentObject disable it
            return disableStyle;
        } else {
            return {}
        }
    }

    function disableJumpBack() {
        if (contentIndex === 0) {
            return disableStyle;
        } else {
            return {}
        }
    }

    function nextContent() {
        //contentArray 
        //contentIndex
        console.log('contentArray', contentArray);
        const nextContent = contentArray[contentIndex + 1];
        console.log('next content', nextContent, contentArray, contentIndex);
        if (nextContent.type === 'slide-group') {
            setSelectedChapterId(nextContent.id);
            setSelectedTestId(null);
            setContentIndex(contentIndex + 1)
        }
        if (nextContent.type === 'test') {
            setSelectedTestId(nextContent.id);
            setSelectedChapterId(null);
            setContentIndex(contentIndex + 1)
        }
    }

    function previousContent() {
        const previousContent = contentArray[contentIndex - 1];
        if (previousContent.type === 'slide-group') {
            setPrevious(true);
            setSelectedChapterId(previousContent.id);
            setSelectedTestId(null);
            setContentIndex(contentIndex - 1)
        }
        if (previousContent.type === 'test') {
            setPrevious(true);
            setSelectedTestId(previousContent.id);
            setSelectedChapterId(null);
            setContentIndex(contentIndex - 1)
        }
    }
    if (isStarted) {
        return (<>
            {
                questionsArray ? (
                    <section className="flex flex-col h-full px-4" style={{ width: '100%' }}>
                        {/* <div className="flex items-center justify-center gap-2 pb-2">
                            <label className="text-xs">No. of questions to display during test</label> <div className="p-1 text-xs border-2 border-solid border-gray-400 rounded-lg w-12" type="number"></div>
                            <label className="text-xs">Time duration for the test</label> <div className="p-1 text-xs border-2 border-solid border-gray-400 rounded-lg w-12" type="number"></div> <span className="text-xs">Hr</span> <div className="p-1 text-xs border-2 border-solid border-gray-400 rounded-lg w-12" type="number"></div> <span className="text-xs">Min</span>
                        </div> */}
                        <div className="h-full w-full flex justify-center overflow-auto py-0 relative">
                            <div className="flex flex-col gap-4 w-full h-full overflow-auto bg-white realtive p-2 min-h-full">
                                <div className="flex gap-2 ">
                                    {/* q, currentQuestionId , setQuestion ,setCurrentQuestionId */}
                                    {
                                        questionsArray.map((q , index) => {
                                            return (
                                                <PreviewTestThumbnail q={q} currentQuestionId={currentQuestionId} setQuestion={setQuestion} setCurrentQuestionId={setCurrentQuestionId} questionsArray={questionsArray} index={index}/>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    question ? (
                                        <div className="w-full max-w-[800px]">
                                            <div className="w-full mb-1 border p-1 text-xs min-h-20"  >{question.answer_type !== 'audio' ? question.text : `Read the sentence out loud "${question.text}"`}</div>
                                            <div className="flex justify-between items-center mb-2">
                                            </div>
                                            {
                                                question.image_data.image ? (

                                                    <div style={{ width: question.image_data.width, height: question.image_data.height }}>
                                                        <img src={question.image_data.image} style={{ height: "100%", width: "100%" }}></img>
                                                    </div>

                                                ) : (null)
                                            }
                                            {
                                                question.answer_type !== 'audio' ? (
                                                    <>
                                                        <section
                                                            className="text-xs flex flex-col gap-2"
                                                            // style={{
                                                            //     display: 'grid',
                                                            //     gridTemplateColumns: 'auto auto',
                                                            //     gridTemplateRows: `repeat(${Math.ceil((question.options.length) / 2)}, 80px)`,
                                                            //     gap: '1rem'
                                                            // }}
                                                        >
                                                            {
                                                                question.answer_type === 'single' ? (
                                                                    question.options.map((option, optionIndex) => {
                                                                        return (
                                                                            <div className="flex h-[40px] gap-1 border border-solid border-gray-300 items-center px-2 py-0" key={optionIndex} >
                                                                                <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-[16px]" >{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                                                                <input className="text-xl" type="radio" name={`radio${question.id}`} key={`single${optionIndex}${question.id}`}/>
                                                                                <div className="w-4/5 outline-none text-xs" type="text" >{option}</div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    question.answer_type === 'multiple' ? (
                                                                        question.options.map((option, optionIndex) => {
                                                                            console.log('maping options ', option, question.correct_answer.includes(option));
                                                                            return (
                                                                                <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 " key={optionIndex} >
                                                                                    <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500">{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                                                                    <input className="text-xl" type="checkbox" name="radioCheckBox" key={`multiple${optionIndex}`}/>
                                                                                    <div className="w-4/5 outline-none text-xs" type="text" >{option}</div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        )
                                                                    ) : (
                                                                        null
                                                                        // return component for audio 
                                                                    )
                                                                )
                                                            }
                                                        </section>
                                                    </>
                                                ) : (null)
                                            }
                                        </div>
                                    ) : (null)
                                }
                            </div>
                        </div>
                        {/* <div className="flex py-0 items-center justify-center">
                            <div className="flex items-center h-10 gap-3">
                                {
                                    questionsArray[0].id === currentQuestionId ? (
                                        <svg style={disableJumpBack()} onClick={previousContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                        </svg>
                                    ) : (
                                        <svg style={disablePrevious()} onClick={previousQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>
                                    )
                                }

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-10">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                {
                                    questionsArray[questionsArray.length - 1].id === currentQuestionId ? (
                                        <svg style={disableJumpNext()} onClick={nextContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    ) : (
                                        <svg style={disableNext()} onClick={nextQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    )
                                }
                            </div>
                        </div> */}
                    </section>
                ) : (null)
            }
        </>);
    } else {
        return (
            <>
                <div className="flex h-full w-full px-4 justify-center items-center">
                    <div className="w-2/3 border rounded-md">
                        <div>
                            Test
                        </div>
                        <div>
                            Name:<span>{`${testName ? testName : ''}`}</span>
                        </div>
                        <div>
                            Duration:
                        </div>
                        <div>
                            <button onClick={() => { setIsStarted(true) }} className="border border-gray-100 bg-slate-700 text-white px-2 py-1">Start</button>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}

export default NewPreviewTestComponent;