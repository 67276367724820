const PreviewThumbnail = ({ slide, paginate , currentSlideId , slideIndex }) => {

    console.log("thumbnail called " ,slide, paginate , currentSlideId , slideIndex )
    function handleThumbnailClick() {
        //if its not the current slide we need to set and save
        paginate(slide.id)
    }

    if (slide) {
        return (
            <div className="shrink-0 flex items-center justify-center relative p-1 w-10 h-10 bg-white" style={slide.id === currentSlideId ? { cursor: 'pointer', overflow: 'hidden', border: '2px solid #382F8A', backgroundColor: '#382F8A', color: 'white' } : { cursor: 'pointer', overflow: 'hidden', border: "1px solid #E0E0E0" }} onClick={handleThumbnailClick}>
                <span className="text-sm p-0.5">{slideIndex + 1}</span>
            </div>
        )
    }

    // return (
    //     <>
    //         {
    //             slide ? (

    //             ): (null)
    //     }

    //     </>
    // );
}

export default PreviewThumbnail;