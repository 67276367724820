import React, { useState, useRef, createRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEmail } from "./EmailContext.js"; // Adjust the path accordingly
import { BASEURL } from "../../../constants.js";
import toast from 'react-hot-toast'
import Image from '../../assets/image.png'

const Otp = () => {

    const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);
    const [error, setError] = useState("");
    const { email: userEmail, setEmail } = useEmail(); // Use useEmail hook
    const inputRefs = useRef([...Array(5)].map(() => createRef()));
    const navigate = useNavigate();

    // Function to handle changes in OTP input fields
    const handleOtpChange = (index, value) => {
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);

            if (value !== "" && index < 4) {
                inputRefs.current[index + 1].current.focus();
            }
        }
    };

    // Function to verify OTP
    const verifyOtp = async () => {

        try {
            console.log("Verifying OTP...");

            // Use the email state directly (assumed the user entered it during the password reset process)
            const enteredOTP = otpValues.join("");

            // Send a POST request to the '/verify-otp' endpoint
            console.log("Sending request...");
            const response = await axios.post(`${BASEURL}/api/verify-otp`, {
                email: userEmail,
                enteredOTP,
            });

            console.log("Response:", response.data);

            // Check if the OTP is valid
            if (response.data.isValidOTP) {
                // If the OTP is valid, the server should include the user's email in the response

                // Navigate to the next step (e.g., '/resetPassword') after successful verification
                console.log("OTP is valid. Navigating to /resetPassword...");
                navigate("/resetPassword");
            } else if (response.data.isValidOTP === false) {
                // If the OTP is invalid or expired, set an error message
                console.log("Invalid OTP or OTP expired. Please try again.");
                setError("Invalid OTP or OTP expired. Please try again.");
            } else {
                // If the OTP is invalid or expired, set an error message
                console.log("Invalid OTP or OTP expired. Please try again.");
                setError("Invalid OTP or OTP expired. Please try again.");
                toast.error("Invalid OTP or OTP expired. Please try again.");
            }
        } catch (error) {
            // If there's an error during the OTP verification, log the error and set an error message
            console.error("Error verifying OTP:", error);
            setError("An error occurred while verifying OTP. Please try again.");
            toast.error("An error occurred while verifying OTP. Please try again.");
        }
    };


    return (
        <div>

            <div className="bg-white dark:bg-gray-900">
                <div className="flex justify-center h-screen">
                    {/* <div className="hidden lg:block lg:w-3/5 cover-image bg-center h-full ">
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-30">
                            <div>
                                <h2 className="text-4xl font-bold text-white">Brand</h2>

                                <p className="max-w-xl mt-3 text-gray-300">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto sit consectetur nam accusamus possimus autem incidunt molestiae quibusdam aliquid, impedit nisi perspiciatis odio ducimus cumque nostrum! Quas, illum vel sequi molestiae vero corporis, accusantium velit, molestias totam necessitatibus voluptatem quam. Natus quaerat a ullam facere, cupiditate non itaque culpa aliquam?</p>
                            </div>
                        </div>
                    </div> */}


                    <div class="lg:w-3/5 bg-black h-full hidden lg:block ">
                        <img class="object-cover w-full h-full bg-gray-900 bg-opacity-30" src={Image} alt="Side" />
                    </div>
                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/5">
                        <div className="flex-1">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">BlissIQ</h2>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Verify Otp</p>

                                <p className="max-w-xl mt-3 text-gray-400">Enter the One-Time Password (OTP) sent to your registered mobile
                                    number or email address to complete the verification process.</p>
                            </div>

                            <div className="mt-8">
                                <form>
                                    <div className="flex flex-row w-full items-center justify-center tracking-wider gap-4">
                                        {/* <label for="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email</label> */}

                                        {otpValues.map((value, index) => (
                                            <input
                                                key={index}
                                                type="tel"
                                                className="block  px-4 py-2 mt-2 h-11 w-11 rounded-md outline-none text-lg text-left border-gray-300 shadow-sm text-gray-700 placeholder-gray-400 bg-white border dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                                value={value}
                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                ref={inputRefs.current[index]}
                                            />
                                        ))}

                                    </div>

                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                console.log("Button clicked");
                                                verifyOtp();
                                            }}
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Verify OTP
                                        </button>
                                    </div>

                                </form>

                                <p className="mt-6 text-sm text-center text-gray-400"><a href="#" className="text-blue-500 focus:outline-none hover:text-red-500">Resend OTP</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Otp