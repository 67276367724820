import {useState} from 'react';

const PowerPoint = ({ contentObj, setCurrentSlideData , setIsDataSaved }) => {
    
    // const handleUpload = () => {
    //     setCurrentSlideData((currentSlide) =>{
    //         return {
    //             ...currentSlide , content : currentSlide.content.map((contentObject) => {
    //                 if (contentObject.id === contentObj.id) {
    //                     return {
    //                         ...contentObject , data : url
    //                     }
    //                 }
    //                 return {
    //                     ...contentObject
    //                 }
    //             })
    //         }
    //     })
    //     setIsDataSaved(false);
    // }

    if (contentObj.data) {

        let iframeString = contentObj.data;
        const className = `class = "w-full"`;
        let newIframeString = iframeString
        .replace(/width="[^"]*"/, 'width="100%"')  // Set width to 100% for responsive width
        .replace(/height="[^"]*"/, 'height="500px"')  // Set height to 600px
        .replace('<iframe', `<iframe allowfullscreen ${className}`);
        

        return  (
            <div dangerouslySetInnerHTML={{__html: newIframeString}}>
            </div>
        );
    }
    // } else {
    //     return (
    //         <div>
    //             <h3 className='font-bold text-lg'>Instructions:</h3>
    //             <ol className='list-decimal pl-4'>
    //                 <li>Save your presentation to OneDrive.com.</li>
    //                 <li>Open your presentation in PowerPoint for the web. On the File tab of the Ribbon, click Share, and then click Embed.</li>
    //                 <li>To create the HTML code to embed your file in the web page, click Generate </li>

    //                 <li>In the Embed box, under Dimensions, select the correct dimensions for the blog or web page.</li>

    //                 <li>Under Embed Code, right-click the code, click Copy, and then click Close.</li>
    //                 <li>Paste the Embed code below and upload.</li>
    //             </ol>
    //             <textarea className='border border-gray-500 rounded-xl outline-none p-1' type="url" onChange={handleUrlInput} /> <br/>
    //             <button className='px-3 py-1 border border-solid border-slate-400 rounded-md text-white bg-slate-500' onClick={handleUpload}>Upload</button>
    //         </div>
    //     );
    // }

}

export default PowerPoint;