import React, { useEffect, useState } from "react";
import Image from '../../assets/image.png'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "../../../constants.js";
import toast from 'react-hot-toast';
import { jwtDecode } from "jwt-decode";


const Login = () => {

    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    // useEffect for Axios interceptor logic
    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                // Add configurations here
                const token = localStorage.getItem("auth");
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (err) => {
                return Promise.reject(err);
            }
        );

        return () => {
            // Cleanup the request interceptor
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);


    const handleLogin = (event) => {
        event.preventDefault();
        
        axios
            .post(`${BASEURL}/login`, credentials)
            .then((res) => {
                console.log("Login Response:", res); // Log the entire response for debugging

                if (res.data.success) {
                    // Successful authentication
                    // Access token with res.data.token
                    localStorage.setItem("auth", res.data.token);

                    // Decode the token to get the user's role
                    const decoded = jwtDecode(res.data.token);
                    const userRole = decoded.role;

                    // Navigate to the appropriate route based on the user's role
                    switch (userRole) {
                        case "creator":
                            navigate("/home"); // Or the appropriate teacher portal route
                            break;
                        case "student":
                            navigate("/student/home"); // Or the appropriate student portal route
                            break;
                        default:
                            setError("Invalid user role");
                            break;
                    }
                } else {
                    setError("User Id or password is incorrect");
                    toast.error("User Id or password is incorrect");
                }
            })
            .catch((err) => {
                console.error("Login Error:", err); // Log the error for debugging
                toast.error("User Id or password is incorrect");
                setError("User Id or password is incorrect");
            });
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        
    <div className="bg-white  ">
                <div className="flex justify-center h-screen">

                    <div class="lg:w-3/5 bg-black h-full hidden lg:block">
                        <img class="object-cover w-full h-full bg-gray-900 bg-opacity-30" src={Image} alt="Side" />
                    </div>
                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/5">
                        <div className="flex-1">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-center text-gray-700  ">BlissIQ</h2>

                                <p className="mt-3 text-gray-500  ">Log in to access your account</p>
                            </div>

                            <div className="mt-8">
                                <form>
                                    <div>
                                        <label for="email" className="block mb-2 text-sm text-gray-600  ">Email</label>

                                        <input type="email"
                                            value={credentials.email}
                                            onChange={(e) =>
                                                setCredentials({
                                                    ...credentials,
                                                    email: e.target.value,
                                                })
                                            } placeholder="Ex: xyz@gmail.com" className="block w-full px-4 py-3 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md         focus:border-blue-400   focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <div className="flex justify-between mb-2">
                                            <label for="password" className="text-sm text-gray-600  ">Password</label>
                                            <Link  to="/forgotPassword" className="text-sm text-gray-400 text-blue-500 focus:outline-none hover:text-red-500">Forgot password?</Link>
                                        </div>

                                        <input type={showPassword ? "text" : "password"}
                                            value={credentials.password}
                                            placeholder="password"
                                            onChange={(e) =>
                                                setCredentials({
                                                    ...credentials,
                                                    password: e.target.value,
                                                })
                                            } className="block w-full px-4 py-3 my-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md         focus:border-blue-400   focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <button onClick={handleLogin}
                                            className="w-full px-4 py-3 my-3 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Log in
                                        </button>
                                    </div>

                                </form>

                                <p className="mt-6 text-sm text-center text-gray-400">
                                    <Link to="/login/adminLogin" className="text-blue-500 focus:outline-none hover:text-red-500">Log in as Admin</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


    )
}

export default Login