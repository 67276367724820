import { useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import CourseCreator from "../CourseCreator/CourseCreator";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from '../../../../constants';
import Test from "../CourseCreator/Test";
import Header from "./Header";

const CourseBuilder = () => {

  const [sidebarToggle , setSidebarToggle] = useState(false);

  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [questionsArray, setQuestionsArray] = useState(null);
  const [question, setQuestion] = useState(null)
  const [numberOfQuestions, setNumberOfQuestions] = useState(null);
  const [testTimeMinutes, setTestTimeMinutes] = useState(null);

  const [currentSlideId, setCurrentSlideId] = useState(null); // will contain the id of the current slide
  const [currentSlideData, setCurrentSlideData] = useState(null);
  const [slidesArray, setSlidesArray] = useState(null);

  const [sidebarData, setSidebarData] = useState(null);
  const [courseInfo, setCourseInfo] = useState(null);
  const [imagesArray, setImagesArray] = useState([]);

  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null); // stores the subtopic id. passed to course creator bcuz it needs it when adding a slide 
  const [selectedTestId, setSelectedTestId] = useState(null); //we will set this when user clicks on a test
  const [highlight, setHighlight] = useState("");

  //sidebar accordion uses the following state to open a topic dropdown by default.
  const [semesterDropdown, setSemesterDropdown] = useState('');
  const [type, setType] = useState(""); //req but will only have 1 type //set this to subTopic when clicked on subtopic and test when clicked on test 

  //below state is used to execute the useEffect in course creator component which sets slidesArray state when a semster is deleted.
  const [isDeleted, setIsDeleted] = useState(false); //req
  //flag to store if data is saved or not
  const [isDataSaved, setIsDataSaved] = useState(true); //req
  const [showSaveDataModal, setShowSaveDataModal] = useState(false); //req
  const [loading, setLoading] = useState(true); //req

  const { courseId } = useParams(); //req 
  const location = useLocation();
  const baseRoute = location.pathname.split('/')[1];
  const navigate = useNavigate();


  const notify = (message) => {
    if (message) {
      toast.error(message, {
        duration: 2000,
      });
    } else {
      toast.success("Successfully saved !!");
    }
  };

  console.log("parent", isDataSaved);

  async function fetchSideBarData() {
    const sidebarHierarchy = await axios.get(`${BASEURL}/api/get-sidebar-hierarchy`, {
      params: {
        courseId: courseId,
      },
    })

    return sidebarHierarchy;
  }

  async function fetchCourseInfo() {
    const courseInfo = await axios.get(`${BASEURL}/api/fetch-course-info`, {
      params: {
        courseId: courseId,
      },
    })

    return courseInfo;
  }

  async function fetchSubTopicData(selectedChapterId) {
    const subTopicData = await axios.get(`${BASEURL}/api/get-sub-topic-data`, {
      params: {
        subTopicId: selectedChapterId
      }
    })

    return subTopicData;
  }

  async function fetchSubTopicImages(subTopicArray) {
    const arrayOfImageIdArray = slidesArray.map((slideObject, index) => {
      if (slideObject.imageIdArray) {
        return slideObject.imageIdArray;
      } else {
        return []
      }
    })

    console.log('arrayOfImageIdArray', arrayOfImageIdArray)
    //array of imageIdArray will have an empty array for slides which dont have any images so it will always have length

    const imageKeysArray = arrayOfImageIdArray.reduce((acc, item) => acc.concat(item));
    console.log('images to be fetched array', imageKeysArray);

    if (imageKeysArray.length) {
      const imagesPromiseArray = imageKeysArray.map((key, index) => {
        return axios.get(`${BASEURL}/api/get-image`, {
          params: {
            imageId: key,
            index: index
          }
        })
      })

      const images = await Promise.all(imagesPromiseArray)
      return images;
    } else {
      //there are no images in the subTopic
    }
  }

  useEffect(() => {
    if (sidebarData) {
      if (selectedChapterId) {
        console.log('selectedSubtopicId', selectedChapterId);
        axios.get(`${BASEURL}/api/get-sub-topic-data`, {
          params: {
            subTopicId: selectedChapterId
          }
        }).then((response) => {
          //----------------------------------------------------
          const slidesArray = response.data;
          const arrayOfImageIdArray = slidesArray.map((slideObject, index) => {
            if (slideObject.imageIdArray) {
              return slideObject.imageIdArray;
            } else {
              return []
            }
          })

          console.log('arrayOfImageIdArray', arrayOfImageIdArray)
          //array of imageIdArray will have an empty array for slides which dont have any images so it will always have length

          const imageKeysArray = arrayOfImageIdArray.reduce((acc, item) => acc.concat(item));
          console.log('images to be fetched array', imageKeysArray);
          //if imageKeysArray is empty there are no images to be fetched just set the data
          if (imageKeysArray.length) {
            const imagesPromiseArray = imageKeysArray.map((key, index) => {
              return axios.get(`${BASEURL}/api/get-image`, {
                params: {
                  imageId: key,
                  index: index
                }
              })
            })
            //let single = arr.reduce((elem1, elem2) => elem1.concat(elem2));
            //the response we will get will have { id , index , dataURL }
            Promise.all(imagesPromiseArray)
              .then((imageResponse) => {
                //response will be array of array ? [ { data : { dataUrl : '' } } {} ]
                console.log('imageResponse', imageResponse);
                imageResponse.forEach((resObject, index) => {
                  const imageUrl = resObject.data.dataUrl;
                  const contentObjectId = imageKeysArray[index];

                  //how to find slideIndex 
                  //the slide where the key is present in the imageIdArray 
                  const slideIndex = slidesArray.findIndex(slide => slide.imageIdArray.includes(contentObjectId));
                  //find the contentObjectIndex 
                  const targetSlide = slidesArray[slideIndex];
                  const contentObjectIndex = targetSlide.content.findIndex(contentObject => contentObject.id === contentObjectId);

                  console.log("error ", slidesArray[slideIndex], slidesArray[slideIndex].content, slidesArray[slideIndex].content[contentObjectIndex])

                  if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Image') {
                    slidesArray[slideIndex].content[contentObjectIndex].data.imgData = imageUrl;

                  }
                  if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Quiz') {
                    slidesArray[slideIndex].content[contentObjectIndex].data.imageData.image = imageUrl;
                  }
                })
                setSlidesArray(slidesArray);
                setCurrentSlideId(slidesArray[0].id);
                setCurrentSlideData(slidesArray[0]);
              })
          } else {
            setSlidesArray(slidesArray);
            setCurrentSlideId(slidesArray[0].id);
            setCurrentSlideData(slidesArray[0]);
          }
        })
      } else {
        //this will execute on mount as well as on subtopic delete
        setSlidesArray(null);
        setCurrentSlideData(null);
        setCurrentSlideId(null);
        //if there where any images that were bring edited remove those 
        setImagesArray([]);
      }

      if (selectedTestId) {
        axios.get(`${BASEURL}/api/get-test-data`, {
          params: {
            testId: selectedTestId
          }
        }).then((response) => {

          axios.get(`${BASEURL}/api/get-test-duration`, {
            params: {
              testId: selectedTestId
            }
          }).then((getDurationResponse) => {
            //response should be questionsArray 
            //well we are not setting anything here 

            const questionsArray = response.data;
            const { testTimeMinutes, numberOfQuestions } = getDurationResponse.data[0];

            //[{ } , { } ]

            const imagekeyArray = questionsArray.map((question) => {
              if (question.image_data.image) {
                return question.image_key
              }
            }).filter((key) => key)

            const imagePromisesArray = imagekeyArray.map((key) => {
              if (key) {
                return axios.get(`${BASEURL}/api/get-image`, {
                  params: {
                    imageId: key
                  }
                })
              }
            })

            Promise.all(imagePromisesArray)
              .then((imagesResponse) => {
                //[{ dataUrl: urlObject, status: "success" }]

                imagesResponse.forEach((responseObj, index) => {
                  const key = imagekeyArray[index];
                  const questionIndex = questionsArray.findIndex(question => question.image_key === key);
                  questionsArray[questionIndex] = { ...questionsArray[questionIndex], image_data: { ...questionsArray[questionIndex].image_data, image: responseObj.data.dataUrl } }
                })
                setQuestionsArray(questionsArray);
                setQuestion(questionsArray[0]);
                setCurrentQuestionId(questionsArray[0].id);
                setNumberOfQuestions(numberOfQuestions ? numberOfQuestions : 0);
                setTestTimeMinutes(testTimeMinutes ? testTimeMinutes : 0);
              })
          })
        })
      } else {
        setQuestionsArray(null);
        setQuestion(null);
        setCurrentQuestionId(null);
        //if there where any images that were bring edited remove those 
        setImagesArray([]);
      }
    }
  }, [selectedChapterId, selectedTestId, type])

  useEffect(() => {
    const token = localStorage.getItem("auth");
    if (!token) {
      navigate("/login");
    } else {
      // decodeToken
    }
  }, [navigate]);



  useEffect(() => {
    const fetchSidebarHierarchy = axios.get(`${BASEURL}/api/get-sidebar-hierarchy`, {
      params: {
        courseId: courseId,
      },
    })

    const fetchCourseInfo = axios.get(`${BASEURL}/api/fetch-course-info`, {
      params: {
        courseId: courseId,
      },
    })

    axios.all([fetchSidebarHierarchy, fetchCourseInfo]).then(axios.spread((responseForSidebar, responseForCourseInfo) => {
      console.log('fetch course info ', responseForCourseInfo.data.rows, responseForCourseInfo)
      setCourseInfo(responseForCourseInfo.data.rows);
      console.log('res', responseForSidebar);
      setLoading(false);

      const fetchedSidebarData = responseForSidebar.data;

      if (fetchedSidebarData.topics.length) {
        setSidebarData(fetchedSidebarData);

        if (fetchedSidebarData.topics[0].content.length) {

          if (fetchedSidebarData.topics[0].content[0].type === 'slide-group') {
            setSelectedChapterId(fetchedSidebarData.topics[0].content[0].id);
            setSelectedTopicId(fetchedSidebarData.topics[0].id);
          }

          if (fetchedSidebarData.topics[0].content[0].type === 'test') {
            setSelectedTestId(fetchedSidebarData.topics[0].content[0].id);
            setSelectedTopicId(fetchedSidebarData.topics[0].id);
          }
        }
      } else {
        setLoading(false);
        setSidebarData({ topics: [] });
      }
    }))
  }, []);

  function autoSaveSlide() {
    const formdata = new FormData();

    imagesArray.forEach((imageObject, index) => {
      formdata.append('images', imageObject.data);
      formdata.append(`ids`, imageObject.id);
    });

    formdata.append('slide', JSON.stringify(currentSlideData));

    return axios.post(`${BASEURL}/api/auto-save-slide`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  function autoSaveQuestion() {
    let formdata = new FormData();
    imagesArray.forEach((imageObject, index) => {
      formdata.append('images', imageObject.data); //req.files 
      formdata.append(`ids`, imageObject.id);
    });

    formdata.append('question', JSON.stringify(question));
    formdata.append('testTimeMinutes', testTimeMinutes)
    formdata.append('testId', selectedTestId);

    return axios.post(`${BASEURL}/api/auto-save-question`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }


  function validateQuestion() {
    if (!question.text.trim()) {
      toast.error("Question text cannot be empty!");
      return false;
    }

    if (!question.options.some(option => option.trim())) {
      toast.error("Please provide at least one valid option.");
      return false;
    }

    if (question.correct_answer.length === 0) {
      toast.error("Please select the correct answer.");
      return false;
    }


    if (!testTimeMinutes) {
      toast.error("Please provide time for the test.");
      return;
    }

    return true; // Validation passed
  }

  function handleSaveCourse(type) {
    try {
      //if chapter or if test 
      if (selectedTestId) {

        if (!question) {
          return;
        }

        if (!validateQuestion()) {
          // If validation fails, return early
          return;
        }


        const formdata = new FormData();
        imagesArray.forEach((imageObject, index) => {
          formdata.append('images', imageObject.data); //req.files 
          formdata.append(`ids`, imageObject.id);
        });
        formdata.append('courseId', JSON.stringify(courseId));
        formdata.append('question', JSON.stringify(question));
        formdata.append('numberOfQuestions', numberOfQuestions)
        formdata.append('testTimeMinutes', testTimeMinutes)
        formdata.append('testId', selectedTestId);
        axios.post(`${BASEURL}/api/save-question`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          notify()
          setIsDataSaved(true);
          setImagesArray([]);
          setQuestionsArray((questionArray) => {
            return questionArray.map((q) => {
              if (q.id === currentQuestionId) {
                return { ...question }
              } else {
                return q;
              }
            })
          })
        })
      } else {

        if (!currentSlideData) {
          return;
        }

        const formdata = new FormData();
        imagesArray.forEach((imageObject, index) => {
          formdata.append('images', imageObject.data);
          formdata.append(`ids`, imageObject.id);
        });
        console.log('sending slide ', currentSlideData, JSON.stringify(currentSlideData), JSON.stringify(courseId));
        formdata.append('slide', JSON.stringify(currentSlideData));
        formdata.append('courseId', JSON.stringify(courseId));
        axios.post(`${BASEURL}/api/save-slide`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => { //need to change the response and check for success message
          //console.log('course saved' , response);
          if (response.data.status === 'success') {
            setIsDataSaved(true);
            setImagesArray([]);
            setSlidesArray((slideArray) => {
              return slideArray.map((slide) => {
                if (slide.id === currentSlideData.id) {
                  return { ...currentSlideData }
                } else {
                  return slide
                }
              })
            })
            //update the slidesArray to reflect the updated slide content. DONT FORGET THIS 6/3/2024.
            notify();
            if (type === 'logout') {
              localStorage.removeItem('auth');
              navigate('/login');
            }
          }
        })
      }

    } catch (error) {
      console.log('error saving the course')
    }
  }

  function handleAllCoursesClick(e) {
    if (!isDataSaved) {
      e.preventDefault();
      setShowSaveDataModal(true);
      return;
    }
  }

  return (
    <>
      {
        !loading ? (
          <div className="flex h-screen bg-white">
            <>
              <SideBar
                sidebarToggle = {sidebarToggle}
                setSelectedTestId={setSelectedTestId}
                currentSlideData={currentSlideData}
                autoSaveQuestion={autoSaveQuestion}
                autoSaveSlide={autoSaveSlide}
                setIsDeleted={setIsDeleted}
                isDataSaved={isDataSaved}
                setShowSaveDataModal={setShowSaveDataModal}
                selectedChapterId={selectedChapterId}
                sidebarData={sidebarData}
                setSidebarData={setSidebarData}
                highlight={highlight}
                setHighlight={setHighlight}
                handleSaveCourse={handleSaveCourse}
                setSelectedChapterId={setSelectedChapterId}
                semesterDropdown={semesterDropdown}
                setSemesterDropdown={setSemesterDropdown}
                selectedTestId={selectedTestId}
                setImagesArray={setImagesArray}
                setSelectedTopicId={setSelectedTopicId}
                selectedTopicId={selectedTopicId}
                validateQuestion={validateQuestion}
                handleAllCoursesClick={handleAllCoursesClick}
                baseRoute={baseRoute}
              />
              <div className="flex flex-col h-full transition-all duration-300" style={{ width: sidebarToggle ? '100%' : 'calc( 100vw - 300px)'}}>
                <Header
                  currentSlideData={currentSlideData}
                  setCurrentSlideData={setCurrentSlideData}
                  currentSlideId={currentSlideId}
                  setCurrentSlideId={setCurrentSlideId}
                  slidesArray={slidesArray}
                  setSlidesArray={setSlidesArray}
                  setSidebarToggle = {setSidebarToggle} 
                  baseRoute={baseRoute}
                  isDataSaved={isDataSaved}
                  setIsDataSaved={setIsDataSaved}
                  setShowSaveDataModal={setShowSaveDataModal}
                  showSaveDataModal={showSaveDataModal}
                  courseInfo={courseInfo}
                  selectedChapterId={selectedChapterId}
                  selectedTestId={selectedTestId}
                  handleSaveCourse={handleSaveCourse}
                  questionsArray={questionsArray}
                  currentQuestionId={currentQuestionId}
                  setQuestionsArray={setQuestionsArray}
                  question={question}
                  setQuestion={setQuestion}
                  setCurrentQuestionId={setCurrentQuestionId}
                   />
                {
                  selectedTestId ? (
                    <Test
                      imagesArray={imagesArray}
                      question={question}
                      questionsArray={questionsArray}
                      setQuestion={setQuestion}
                      setQuestionsArray={setQuestionsArray}
                      currentSlideId={currentSlideId}
                      isDataSaved={isDataSaved}
                      setIsDataSaved={setIsDataSaved}
                      currentQuestionId={currentQuestionId}
                      setCurrentQuestionId={setCurrentQuestionId}
                      selectedTestId={selectedTestId}
                      numberOfQuestions={numberOfQuestions}
                      testTimeMinutes={testTimeMinutes}
                      setNumberOfQuestions={setNumberOfQuestions}
                      setTestTimeMinutes={setTestTimeMinutes}
                      setImagesArray={setImagesArray}
                      autoSaveSlide={autoSaveSlide}
                      autoSaveQuestion={autoSaveQuestion}
                      selectedTopicId={selectedTopicId}
                      validateQuestion={validateQuestion}
                    />
                  ) : (
                    <CourseCreator
                      currentSlideData={currentSlideData}
                      setCurrentSlideData={setCurrentSlideData}
                      currentSlideId={currentSlideId}
                      setCurrentSlideId={setCurrentSlideId}
                      slidesArray={slidesArray}
                      setSlidesArray={setSlidesArray}
                      selectedChapterId={selectedChapterId}
                      selectedTestId={selectedTestId}
                      isDeleted={isDeleted}
                      setIsDataSaved={setIsDataSaved}
                      setShowSaveDataModal={setShowSaveDataModal}
                      showSaveDataModal={showSaveDataModal}
                      isDataSaved={isDataSaved}
                      setImagesArray={setImagesArray}
                      imagesArray={imagesArray}
                      handleSaveCourse={handleSaveCourse}
                      autoSaveSlide={autoSaveSlide}
                      selectedTopicId={selectedTopicId}
                    />
                  )
                }
              </div>
            </>
          </div>
        ) : (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Loading Course...</div>
        )
      }
    </>
  );
};

export default CourseBuilder;