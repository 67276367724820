import React, { useState, useEffect } from "react";
import Navbar from './Navbar'; // Ensure you import the Navbar component
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { PiStudent } from "react-icons/pi";
import { TbUserStar } from "react-icons/tb";
import Avatar from "../../assets/avatar.jpg";
import axios from "axios";
import { BASEURL } from "../../../constants";
import { LuSchool } from "react-icons/lu";
import { GoBook } from "react-icons/go";
import { PiHandshake } from "react-icons/pi";

const NavLink = ({ to, children, isCollapsed }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <li style={{ listStyleType: 'none' }}>
            <Link
                to={to}
                className={`flex items-center px-4 py-3 my-1 rounded-md transition-colors duration-300
                    ${isActive
                        ? 'bg-blue-500 text-white'
                        : 'text-gray-600 hover:bg-blue-500 hover:text-white'
                    }
                    ${isCollapsed ? 'justify-center' : ''}`}
            >
                {children}
            </Link>
        </li>
    );
};

const Sidebar = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [lastActive, setLastActive] = useState(new Date());

    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);
    const userRole = decoded.role;
    const userId = decoded.user_id;
    const schoolId = decoded.org_id;
    const firstName = decoded.first_name;
    const lastName = decoded.last_name;

    const location = useLocation();
    const navigate = useNavigate();
    const excludedRoutes = [
        "/login",
        "/verify-otp",
        "/forgotPassword",
        "/resetPassword",
    ];
    const shouldExcludeSidebar = excludedRoutes.includes(location.pathname);
    const timeOutDuration = 1000 * 60 * 60; // 1 hour in milliseconds



    useEffect(() => {
        document.addEventListener('keydown', handleUserActivity);
        document.addEventListener('mousemove', handleUserActivity);

        return () => {
            document.removeEventListener('keydown', handleUserActivity);
            document.removeEventListener('mousemove', handleUserActivity);
        };
    }, []);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            localStorage.removeItem('auth');
            navigate('/login');
        }, timeOutDuration - (new Date() - lastActive));

        return () => { clearTimeout(timeOutId); };
    }, [lastActive, navigate]);

    function handleUserActivity() {
        setLastActive(new Date());
    }

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem("auth");
        navigate("/login");
    };

    const [file, setFile] = useState("");

    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    }

    useEffect(() => {
        // Fetch mentor image
        axios
            .get(`${BASEURL}/api/retrieve-profile-image/${userId}/mentor`)
            .then(async (response) => {
                const imageUrl = response.data.dataUrl;

                try {
                    // Check if the image exists in the S3 bucket
                    await checkImageExists(imageUrl);

                    // If the image exists, set the file state
                    setFile(imageUrl);
                } catch (error) {
                    // If the image doesn't exist, set the file state to the default image
                    setFile(Avatar);
                }
            })
            .catch((error) => {
                console.error("Error fetching mentor image:", error);
                // Set file to an empty string when an error occurs
                setFile("");
            });
    }, [userId]);

    return (
        <div className="flex h-screen w-full">
            {!shouldExcludeSidebar && (
                <aside className={`z-50 flex flex-col h-full px-4 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l ${isCollapsed ? 'w-20 items-center overflow-x-hidden' : 'w-64'} transition-all duration-300`}>
                    <div className="flex items-center justify-end">
                        <button onClick={handleToggle} className="text-gray-700 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-col justify-between flex-1 mt-4">
                        <nav className="flex-1 space-y-1">
                            {userRole === 'creator' && (
                                <>
                                    <NavLink to="/home" isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Home</span>}
                                    </NavLink>
                                    <NavLink to="/myCourses/publishedCourses" isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">My Courses</span>}
                                    </NavLink>
                                    <NavLink to='/store' isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Store</span>}
                                    </NavLink>
                                </>
                            )}

                            {userRole === 'student' && (
                                <>
                                    <NavLink to="/student/home" isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Home</span>}
                                    </NavLink>
                                    <NavLink to="/student/myCourses" isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">My Courses</span>}
                                    </NavLink>
                                    <NavLink to='/student/course-points' isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Course Points</span>}
                                    </NavLink>
                                </>
                            )}
                            {userRole === 'admin' && (
                                <>
                                    <NavLink to={`/admin/home/${userId}/${schoolId}`} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Home</span>}
                                    </NavLink>
                                    <NavLink to={`/admin/allCreators/${schoolId}`} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Creators</span>}
                                    </NavLink>
                                    <NavLink to={`/admin/allStudents/${schoolId}`} isCollapsed={isCollapsed}>
                                        <PiStudent className="w-5 h-5" />
                                        {!isCollapsed && <span className="mx-4 font-medium">Learners</span>}
                                    </NavLink>
                                    <NavLink to={`/admin/allMentors/${schoolId}`} isCollapsed={isCollapsed}>
                                        <TbUserStar className="w-5 h-5" />
                                        {!isCollapsed && <span className="mx-4 font-medium">Mentors</span>}
                                    </NavLink>
                                </>
                            )}
                            {userRole === 'superadmin' && (
                                <>
                                    <label className={`px-3 text-xs text-gray-500 uppercase ${isCollapsed ? 'hidden' : 'block'}`}>Dashboard</label>
                                    <NavLink to={`/diactoAdmin/home`} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Home</span>}
                                    </NavLink>

                                    <label className={`px-3 text-xs text-gray-500 uppercase ${isCollapsed ? 'hidden' : 'block'}`}>Management</label>
                                    <NavLink to={`/diactoAdmin/allSchools`} isCollapsed={isCollapsed}>
                                        <LuSchool className="w-5 h-5" strokeWidth={1.5} />
                                        {!isCollapsed && <span className="mx-4 font-medium">Organizations</span>}
                                    </NavLink>
                                    {/* <NavLink to={"/diactoAdmin/allMentors"} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Mentors</span>}
                                    </NavLink>
                                    <NavLink to={"/diactoAdmin/allSponsors"} isCollapsed={isCollapsed}>
                                        <PiHandshake className='w-5 h-5' />
                                        {!isCollapsed && <span className="mx-4 font-medium">Sponsors</span>}
                                    </NavLink> */}

                                    <label className={`px-3 text-xs text-gray-500 uppercase ${isCollapsed ? 'hidden' : 'block'}`}>Courses</label>
                                    <NavLink to={"/diactoAdmin/courses"} isCollapsed={isCollapsed}>
                                        <GoBook className="w-5 h-5" />
                                        {!isCollapsed && <span className="mx-4 font-medium">My Courses</span>}
                                    </NavLink>
                                    {/* <NavLink to={"/diactoAdmin/verifyCourses"} isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Verify Courses</span>}
                                    </NavLink> */}
                                    <NavLink to='/diactoAdmin/store' isCollapsed={isCollapsed}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                                        </svg>
                                        {!isCollapsed && <span className="mx-4 font-medium">Store</span>}
                                    </NavLink>
                                </>
                            )}
                        </nav>
                        <div className={`flex items-center justify-between px-4 -mx-2 mt-6 ${isCollapsed && 'mt-6'}`}>
                            <img className="object-cover w-8 h-8 rounded-full" src={file || Avatar} alt="User avatar" />
                            {!isCollapsed && (
                                <>
                                    <span className="mx-2 font-medium text-gray-800">{firstName} {lastName}</span>
                                    <button onClick={handleLogout} className="ml-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                                        </svg>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </aside>
            )}
            <div className="flex flex-col bg-gray-50 h-full w-[calc(100%-5rem)]">
                <Navbar />
                <main className="p-4 bg-gray-50 overflow-auto">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Sidebar;