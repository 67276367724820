import axios from "axios";
import { BASEURL } from "../../constants";
import { truncateText } from '../Student/util/formatting'
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useRef, useState, useEffect } from "react";

const StudentMyCourses = () => {

    const [studentCourses, setStudentCourses] = useState(null);
    const studentCoursesRef = useRef(null);
    const [coverImages, setCoverImages] = useState({});
    const [searchTerm, setSearchTerm] = useState(""); // Search term state
    const token = localStorage.getItem('auth');

    const decoded = jwtDecode(token);
    const userRole = decoded.role;
    const userId = decoded.user_id;
    const schoolId = decoded.org_id;
    const classId = decoded.class_id;

    const navigate  = useNavigate();
    const debounce = (func, delay) => {
        let timer;

        return (...args) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    const debouncedSearch = debounce(handleSearch, 400);


    function handleSearch(e) {
        let searchString = e.target.value;
        if (e.target.value) {
            const newStudentCourses = studentCoursesRef.current.filter((course) => (
                course.course_name.toLowerCase().includes(searchString.toLowerCase()) || course.subject_name.toLowerCase().includes(searchString.toLowerCase())
            ))
            setStudentCourses(newStudentCourses);
        } else {
            setStudentCourses(studentCoursesRef.current);
        }
    }

    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
            image.src = imageUrl;
        });
    }

    useEffect(() => {
        axios.get(`${BASEURL}/api/get-student-courses`, {
            params: {
                studentId: userId,
                schoolId: schoolId,
                classId: classId
            }
        }).then((response) => {

            studentCoursesRef.current = [...response.data];

            const courses = response.data;
            console.log("student courses" ,courses )
            let imagePromiseArray = [];
            for (let course of courses) {
                const imagePromise = axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
                imagePromiseArray.push(imagePromise)
            }

            const images = {};
            Promise.all(imagePromiseArray)
                .then(async (responses) => {
                    console.log("image promise array response", responses);
                    // [{} , { }, response object]

                    for (let i = 0; i < responses.length; i++) {
                        if (responses[i].data.dataUrl) {
                            const imageExists = await checkImageExists(responses[i].data.dataUrl);
                            if (imageExists) {
                                images[courses[i].id] = responses[i].data.dataUrl;
                            } else {
                                console.log(`Image for course ${courses[i].id} does not exist in S3 bucket`);
                                images[courses[i].id] = null; // or a default image URL
                            }
                        } else {
                            console.log(`No image URL received for course ${courses[i].id}`);
                            images[courses[i].id] = null; // or a default image URL
                        }
                    }
                    setStudentCourses(response.data);
                    setCoverImages(images);
                })
        })
    }, [])

    return (
        <div className="">

            <div className='pt-2 pb-3 flex justify-between '>
                <div className="flex items-center overflow-x-auto whitespace-nowrap">
                    <p className="text-2xl text-slate-900 font-bold cursor-pointer">
                        My Courses
                    </p>
                </div>
                <div className='flex flex-row justify-between flex-x-2'>
                    <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
                        <div className="grid place-items-center w-12 text-gray-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                        <input
                            className="peer w-full outline-none text-sm text-gray-700 pr-2"
                            type="text"
                            id="search"
                            placeholder="Search Course Name"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {
                studentCourses && (
                    <div className="overflow-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">
                        {studentCourses.length === 0 ? (
                            <div className="col-span-full w-full my-0 mx-auto">
                                <div className="flex justify-center my-0 mx-auto w-full">
                                    <div className="flex flex-col items-center text-center max-w-sm">
                                        <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 flex justify-center">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                            </svg>
                                        </p>
                                        <p className="text-xl font-bold mt-2 text-gray-700">Oops! No courses found.</p>
                                        <p className="mt-2">It looks like there are no courses here. Why not create one and get started?</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            studentCourses.map((course, index) => (
                                <div onClick={() => {
                                    navigate(`/CourseDetails/${course.id}/${course.course_id}`)         
                                }} className='bg-white cursor-pointer rounded-md border-solid border border-gray-200 p-4 h-max max-w-md relative m-1 ' >
                                    <div className="flex justify-between">
                                        <h1 className="mb-4 text-md font-semibold text-gray-800">
                                            {truncateText(course.course_name, 20)}
                                        </h1>
                                        {/* <div x-data="{ isOpen: true }" className="relative inline-block ">
                                    <button
                                        className="relative z-10 block p-1 text-gray-700  border border-transparent rounded-md focus:border-blue-500 focus:ring-opacity-40  focus:ring-blue-300 focus:ring focus:outline-none"
                                        onClick={() => toggleDropdown(course.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 font-bold">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                        </svg>

                                    </button>

                                    {dropdownState[course.id] && (
                                        <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl"
                                        >
                                            <Link to={`/publish-view/${course.master_course_id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg>
                                                <span className="mx-1">
                                                    View Course
                                                </span>
                                            </Link>
                                        </div>
                                    )}

                                </div> */}
                                    </div>
                                    {coverImages[course.id] ? (
                                        <img
                                            src={coverImages[course.id]}
                                            alt={`${course.course_name} cover`}
                                            className="object-cover object-center w-full h-64 rounded-lg lg:h-40 transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
                                            onError={(e) => {
                                                console.error(`Error loading image for course ${course.id}:`, e);
                                                e.target.src = 'path/to/fallback/image.jpg';
                                            }}
                                        />
                                    ) : (
                                        <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
                                            <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
                                        </div>
                                    )}

                                    <div className="mt-4">

                                        <p className="mt-2 text-gray-500 h-12 text-sm">
                                            {truncateText(course.course_desc, 58)}
                                        </p>

                                        {/* <div className="flex flex-col w-full gap-2 mt-1 rounded-sm">
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Grade :</span>  {formatClassId(course.class_id)}
                                    </span>
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Difficulty Level :</span>
                                        <span className="capitalize">{course.difficulty_level}</span>
                                    </span>
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Tags :</span>  {formatTags(course.tags)}
                                    </span>
                                </div> */}

                                        <div className='flex flex-row gap-2 justify-around px-1 flex-wrap mt-1'>
                                            <div className="flex flex-col items-start mt-4">
                                                <p className="text-xs text-gray-500">{course.slide_count ? course.slide_count : 0}</p>
                                                <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                                    Slides
                                                </p>
                                            </div>
                                            <div className="flex flex-col items-start mt-4">
                                                <p className="text-xs text-gray-500">{course.test_count ? course.test_count : 0}</p>
                                                <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                                    Tests
                                                </p>
                                            </div>

                                            {/* {course.master_course_id && (
                                        <div className="flex flex-col items-start mt-4">
                                            <p className="text-xs text-gray-500">30+</p>
                                            <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                                Shares
                                            </p>
                                        </div>
                                    )} */}
                                            {/* <div className="text-center flex items-center mt-4  rounded-full">
                                                <button onClick={() => {
                                                    navigate(`/CourseDetails/${course.id}/${course.course_id}`)         
                                                }} type="button" className="inline-flex truncate gap-2 justify-center items-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded-full ">
                                                    Learn More
                                                </button>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>


                            ))

                        )}
                    </div>
                )
            }
        </div>

    );
}

export default StudentMyCourses;