import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';
import axios from 'axios';
import { BASEURL } from '../../../../../constants';
import { PlusSVG, Grip, CrossSVG } from '../../../../Icons/icons';
export function SortableItem({ setShowAddContentModal, setCurrentSlideData, element, slideId, imagesArray, setSlidesArray, setImagesArray, setIsSorted, setIsDataSaved, children
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: element.id });

  const [isHovering, setIsHovering] = useState(false);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function deleteContent() {
    if (element.type === 'Image') {
      if (element.data.imgData) {
        if (imagesArray.some(obj => obj.id === element.id)) {
          //if image data is present and image is in imagesArray that means image was not saved in backend simply delete the image from frontend
          //array.some returns a boolean value.
          //also update the imageIdArray for that component
          setImagesArray((imagesArray) => {
            const newImagesArray = imagesArray.filter(imageObj => imageObj.id !== element.id);
            return newImagesArray;
          });
          setCurrentSlideData((currentSlide) => {
            return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), imageIdArray: currentSlide.imageIdArray.filter(imageKey => imageKey !== element.id) }
          })

        } else {
          //we dont need to setImagesArray to null because imagesArray only contins images that have not been saved.
          //the image is in s3 bucket need to make delete API call
          setCurrentSlideData((currentSlide) => {
            return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), imageIdArray: currentSlide.imageIdArray.filter(imageKey => imageKey !== element.id) }
          })
          axios.delete(`${BASEURL}/api/delete-image`, {
            data: {
              key: element.id
            }
          }).then((response) => {
            if (response.data.message === 'deleted successfully') {

            }
          })
        }

      } else {
        setCurrentSlideData((currentSlide) => {
          return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), imageIdArray: currentSlide.imageIdArray.filter(imageKey => imageKey !== element.id) }
        })
      }
    } else if (element.type === 'Quiz') {
      //if image is uploaded then do futher processing if image was not uploaded directlt delete the component
      if (element.data.imageData.image) {
        //this means that image is there check if it is in s3 bucket or not 
        if (imagesArray.some(imageObj => imageObj.id === element.id)) {
          //means image was not saved to s3 bucket
          //just delete the component and image object from imagesArray 
          setImagesArray((imagesArray) => {
            const newImagesArray = imagesArray.filter(imageObj => imageObj.id === element.id);
            return newImagesArray;
          });
          setCurrentSlideData((currentSlide) => {
            return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), imageIdArray: currentSlide.imageIdArray.filter(imageKey => imageKey !== element.id) }
          })
        } else {
          //means image is saved to s3 bucket delete it and remove it from frontend 
          axios.delete(`${BASEURL}/api/delete-image`, {
            data: {
              key: element.id
            }
          }).then((response) => {
            if (response.data.message === 'deleted successfully') {
              //also set slides data as well
              setCurrentSlideData((currentSlide) => {
                return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), imageIdArray: currentSlide.imageIdArray.filter(imageKey => imageKey !== element.id) }
              })
            }
          })
        }
      } else {
        //simply delete the component
        setCurrentSlideData((currentSlide) => {
          return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id) }
        })
      }
    } else if (element.type === 'Video' && element.data.renderComponent === 'pc_video_component' && element.data.videoKey) {
      //video is uploaded to s3 bucket 
      setCurrentSlideData((currentSlide) => {
        return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), videoIdArray: currentSlide.videoIdArray.filter(key => key !== element.id) }
      })
      axios.delete(`${BASEURL}/api/video-delete`, {
        data: {
          key: element.id
        }
      }).then((response) => {
        console.log('video delete response', response);
      })
    } else {
      //for all the content other than image and quiz hmm....
      console.log("last condition")
      setCurrentSlideData((currentSlide) => {
        return { ...currentSlide, content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id) }
      })
    }
    setIsSorted((isSorted) => !isSorted);//why is this state here?
    setIsDataSaved(false);
  }

  return (
    <Item ref={setNodeRef} style={style} >
      <div id={`element.id`} onMouseEnter={() => {
        console.log('entered')
        setIsHovering(true)
      }} onMouseLeave={() => {
        console.log('mouse leave')
        setIsHovering(false)
      }} className='flex w-full gap-2 justify-center'>
        <div onClick={() => {
          setShowAddContentModal(true)
        }} className={`${isHovering ? 'visible' : 'invisible'} cursor-pointer`}>
          <PlusSVG size="size-4" />
        </div>

        <div className={`text-xs ${isHovering ? 'visible' : 'invisible'} pt-1`} {...attributes} {...listeners}><Grip /></div>

        <div className='grow max-w-[800px]'>{children}</div>

        <div className={`${isHovering ? 'visible' : 'invisible'}`}>
          <span onClick={deleteContent} className='cursor-pointer'>
            <CrossSVG />
          </span>
        </div>

      </div>


    </Item>
  );
}