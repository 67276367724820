import React, {forwardRef , useState} from 'react';

export const Item = forwardRef(({id, setIsHovering , ...props}, ref) => {

  
  return (
    <>
      <div {...props} ref={ref}>{props.children}</div>
    </>
  )
});