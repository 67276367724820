import icons from '../Student/icons/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURL } from '../../constants';
import { truncateText } from '../Student/util/formatting';

const CourseDetails = () => {

    const [hierarchy, setHierarchy] = useState(null);
    const [courseInfo, setCourseInfo] = useState(null);
    const { courseId, sharedCourseId } = useParams();
    const [courseImage, setCourseImage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`${BASEURL}/api/update-course-completion-percentage`, {
            courseId: sharedCourseId,
            masterCourseId: courseId,
        }).then((response) => {
            console.log("response", response);
        })
    }, [])

    useEffect(() => {
        const fetchSidebarHierarchy = axios.get(`${BASEURL}/api/get-sidebar-hierarchy`, {
            params: {
                courseId: courseId,
            },
        })

        fetchSidebarHierarchy.then((response) => {

            axios.get(`${BASEURL}/api/fetch-course-info`, {
                params: {
                    courseId: courseId,
                },
            }).then((res) => {
                console.log("course info ", res)

                const fetchedSidebarData = response.data;

                if (fetchedSidebarData.topics.length) {
                    console.log("fetchedSidebarData", fetchedSidebarData)
                    setHierarchy(fetchedSidebarData);
                } else {
                    setHierarchy({ topics: [] })
                }
                console.log("cousreinfo ", res.data.rows)
                setCourseInfo(res.data.rows)

                axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: courseId }).then((imageResponse) => {
                    console.log("image response ", imageResponse);
                    setCourseImage(imageResponse.data.dataUrl)
                })
            })
        })

    }, [])

    return (
        courseInfo ? (
            <div className="h-dvh w-full box-border flex flex-col gap-4">
                <div className='flex gap-5 items-center'>
                    <button className='inline-flex truncate justify-center items-center rounded-md bg-gray-200 p-2 text-xs font-semibold text-black shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ' onClick={() => { navigate('/student/myCourses') }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                    </button>
                    {/* <span className='font-bold text-lg'>{courseInfo ? courseInfo.course_name : 'Know Basics of Noun'}</span> */}
                </div>
                {/* course detail card */}
                <div className='w-full h-56 bg-white shrink-0 p-4 flex gap-4'>
                    <div className='w-48 h-full shrink-0 border border-gray-200 p-2'>
                        {
                            courseImage && <img className='object-contain' src={courseImage} />
                        }
                    </div>
                    <div className='flex flex-col grow gap-2'>
                        <div className='text-slate-400 text-sm'>
                            A course by {courseInfo ? courseInfo.creator_name : ''}
                        </div>
                        <div className='font-bold text-2xl truncate'>
                            {courseInfo ? courseInfo.course_name : ''}
                        </div>
                        <div >
                            {
                                courseInfo ? truncateText(courseInfo.course_desc, 100) : 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci sapiente magni porro blanditiis nam qui deleniti architecto non illum, explicabo vitae, mollitia minima nobis atque? Labore modi eligendi optio debitis.'
                            }
                        </div>
                        <div>
                            <div className='h-2 w-full rounded-full bg-gray-200'>
                                <div className='h-2 w-[150px] rounded-full bg-gray-400'></div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='text-slate-400 text-sm'>4/30 Topics</div>
                            <div className='text-sm text-slate-400'>25% Complete</div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='flex gap-2'>
                                {
                                    courseInfo && courseInfo.tags.map((tag) => {
                                        return <div className='border p-2 text-sm'>{tag}</div>
                                    })
                                }
                            </div>
                            <div>
                                <Link to={`/CourseConsumption/${courseId}/${sharedCourseId}`} >
                                    <button className='p-2 text-white bg-gray-700'>Start Learning</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=''>
                    <h3 className='font-bold text-lg'>Course Curriculum</h3>
                </div>
                <div className='flex gap-4 w-max px-3 py-3 bg-white border border-gray-400'>
                    <div className='flex'>
                        <div>

                        </div>
                        <span className='flex flex-col '>
                            <span>{courseInfo ? courseInfo.slide_count : '60'}</span>
                            <span className='text-sm'>Slides</span>
                        </span>
                    </div>
                    <div className='h-full w-[2px] bg-gray-200'>

                    </div>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? (courseInfo.video_count ? courseInfo.video_count : '0') : '10'}</span>
                        <span className='text-sm'>Videos</span>
                    </span>
                    <div className='h-full w-[2px] bg-gray-200'>

                    </div>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? (courseInfo.quiz_count ? courseInfo.quiz_count : '0') : '10'}</span>
                        <span className='text-sm'>Quiz</span>
                    </span>
                    <div className='h-full w-[2px] bg-gray-200'>

                    </div>
                    <span className='flex flex-col'>
                        <span>{courseInfo ? courseInfo.test_count : '60'}</span>
                        <span className='text-sm'>Test</span>
                    </span>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Topics</h3>
                    <div className='flex flex-col justify-start'>
                        {
                            hierarchy && (
                                hierarchy.topics.map((topic, index) => {
                                    return (
                                        <div key={index} className='my-5 w-full' style={{ backgroundColor: '#393185' }}>
                                            <div onClick={(event) => {
                                                const targetElement = event.currentTarget;
                                                const dropdown = targetElement.nextElementSibling;

                                                if(dropdown.classList.contains('hidden')){
                                                    dropdown.classList.remove('hidden')
                                                }else{
                                                    dropdown.classList.add('hidden')
                                                }
                                            }} className='p-2 text-white flex items-center gap-3 cursor-pointer'>
                                                <div className='px-3 my-2 text-black' style={{ backgroundColor: '#e6e3fa' }}>
                                                    {`${index + 1}`}
                                                </div>
                                                <div>
                                                    {`${topic.name}`}
                                                </div>
                                            </div>
                                            <div className='p-2 hidden' style={{ backgroundColor: '#fcfbfd', border: 'solid 1px #e1deff' }}>
                                                {topic.content.map((contentObj) => {
                                                    return (
                                                        <div className='flex items-center gap-2'>
                                                            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: '#ffc99f' }}>

                                                            </div>
                                                            <div className='text-bold'>{contentObj.name}</div>
                                                            <div></div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                </div>
            </div>
        ) : (
            <div>
                fetching course details...
            </div>
        )

    )
}

export default CourseDetails;