const PreviewTestThumbnail = ({ q, currentQuestionId , setQuestion ,setCurrentQuestionId , questionsArray , index }) => {
    
    async function switchSlide(questionId) {

        const q = questionsArray.find(item => item.id === questionId);
        
        setQuestion(q);
        setCurrentQuestionId(q.id);
    }
    return (
        <div className={`shrink-0 flex items-center justify-center relative p-1 w-10 h-10 bg-white`}
            style={q.id === currentQuestionId ? { cursor: 'pointer', overflow: 'hidden', border: '2px solid #382F8A', backgroundColor: '#382F8A', color: 'white' } : { cursor: 'pointer', overflow: 'hidden', border: "1px solid #E0E0E0" }}
            onClick={() => { switchSlide(q.id) }}
        >
            <span className="">{index + 1}</span>
        </div>

    );

}

export default PreviewTestThumbnail;