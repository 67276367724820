import React, { useEffect, useState } from 'react';

const YouTubeVideo = ({ videoData, reRenderChid }) => {
  // const [videoUrl, setVideoUrl] = useState('');
  console.log("yt component " , videoData)
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    	//videoData
      if(videoData.ytData.videoId){
        setVideoId(videoData.ytData.videoId);
      }
  }, [])
  // const getVideoId = (url) => {
  //   const regExp =
  //     /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   const match = url.match(regExp);
  //   return match ? match[1] : '';
  // };

  // const handleInputChange = (e) => {
  //   const inputUrl = e.target.value;
  //   setVideoUrl(inputUrl);

  // };

  // function handleYtURLUpload() {
  //   const id = getVideoId(videoUrl);
  //   setVideoId(id);
  // }

  // useEffect(() => {
  //   setVideoData({ ...videoData, videoKey: videoId });
  // }, [videoId, videoUrl])

  // useEffect(() => {
  //   // setVideoUrl(videoData.ytData?.videoUrl);
  //   setVideoId(videoData.videoKey);
  // }, [reRenderChid])

  return (
    <div className='w-full'>
      {videoId && (
        <div className='flex w-full justify-center mt-2'>
          <iframe
            className='w-full h-[400px]'
            src={`https://www.youtube.com/embed/${videoId}?rel=0`}
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default YouTubeVideo;
