const SlideView = ({ slidesArray, currentSlideId }) => {
    const defaultStyle = { backgroundColor: '#726DB0' }
    const finishedStyle = { backgroundColor: '#059669' }
    const highlightedStyle = { backgroundColor: '#fe7d5f', border: '2px solid black' }
    const finishedAndHighlightedStyle = {backgroundColor: '#059669' , border: '2px solid black'}
    return (
        <div className="flex bg-white gap-2 p-3 items-center">
            {
                slidesArray.map((slide, index) => {

                    let styleObj; 

                    if( slide.id === currentSlideId && slide.finished){
                        styleObj = {boxSizing: 'content-box'  , ...finishedAndHighlightedStyle}
                    } else if( slide.id === currentSlideId ){
                        styleObj = {boxSizing: 'content-box'  , ...highlightedStyle}
                    }else if(slide.finished){
                        styleObj = {boxSizing: 'content-box'  , ...finishedStyle}
                    }else{
                        styleObj = {boxSizing: 'content-box'  , ...defaultStyle}
                    }

                    return (<div key={index} className="w-10 h-1 rounded-full" style={styleObj} >

                    </div>)
                })
            }
        </div>
    );
}

export default SlideView;