import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constants";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import '../Global/Styles/tabulatorStyles.css';
import { ReactTabulator } from 'react-tabulator';

import toast from "react-hot-toast";

// Debounce Hook
// This custom hook is used to debounce the search term input, preventing excessive re-renders or API calls when the user is typing.
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

const DiactoAllMentors = () => {
  const { schoolId } = useParams();
  const navigate = useNavigate();
  const [mentorsData, setMentorsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMentors, setFilteredMentors] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [openPopup, setOpenPopup] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [mentorToDelete, setMentorToDelete] = useState(null);
  const [rowData, setRowData] = useState(null);


  // Fetch mentors
  useEffect(() => {
    axios
      .get(`${BASEURL}/api/fetch-all-mentors-diacto`)
      .then((res) => {
        setMentorsData(res.data.mentorsData || []);
      })
      .catch((error) => {
        console.error("Error fetching mentors data:", error);
      });
  }, []);

  // Search Filter 
  useEffect(() => {
    const filtered = mentorsData.filter(
      (mentor) =>
        mentor.first_name
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase()) ||
        mentor.last_name
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase())
    );
    setFilteredMentors(filtered);
  }, [debouncedSearchTerm, mentorsData]);


  // Define columns for the Tabulator table
  const columns = [
    { title: "Sno.", field: "number", width: 70, hozAlign: "center", cssClass: "center-text", headerSort: true, resizable: true },
    { title: "Name", field: "name", headerSort: true, resizable: true },
    { title: "Email", field: "email", headerSort: true, resizable: true },
    { title: "Contact", field: "contact", headerSort: true, resizable: true },
    { title: "Location", field: "location", headerSort: true, resizable: true },
    { title: "Associated By", field: "associatedBy", headerSort: true, resizable: true },
    // { 
    //   title: "Status", 
    //   field: "is_active", 
    //   formatter: (cell) => cell.getValue() ? "Active" : "Inactive",
    //   headerSort: true, 
    //   resizable: true 
    // },
    {
      title: 'Actions',
      formatter: () => {
        return '<button className="action-btn">•••</button>';
      },
      width: 100,
      cellClick: (e, cell) => {
        const rect = e.target.getBoundingClientRect();
        setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX - 200 }); // Adjusted position to the left
        setRowData(cell.getRow().getData());
        setOpenPopup(cell.getRow().getData().id); // Set the row ID to identify the open popup
      },
      headerSort: false
    }
  ];

  // Prepare table data for the ReactTabulator component
  // const data = (filteredMentors || []).map((mentor, index) => ({
  //   id: mentor.user_id,
  //   number: index + 1,
  //   name: `${mentor.first_name} ${mentor.last_name}`,
  //   email: mentor.email,
  //   contact: mentor.contact_number,
  //   location: `${mentor.city}, ${mentor.state}`,
  //   associatedBy: mentor.school_name,
  // }));

  const data = (filteredMentors || []).map((mentor, index) => ({
    id: mentor.user_id,
    number: index + 1,
    name: `${mentor.first_name} ${mentor.last_name}`,
    email: mentor.email,
    contact: mentor.contact_number,
    location: `${mentor.city}, ${mentor.state}`,
    associatedBy: mentor.organisation_name, 
    is_active: mentor.is_active
  }));

  console.log("data " , data )
  // // Navigate to the mentor's profile page
  // const onViewDetails = (rowData) => {
  //   const { id } = rowData;
  //   navigate(`/diactoAdmin/allMentors/${id}`);
  //   console.log('userID', id);
  //   setOpenPopup(null);
  // };

  // // Navigate to the edit mentor page
  // const onEditMentor = (rowData) => {
  //   const { id } = rowData;
  //   console.log('userID', id);
  //   navigate(`/diactoAdmin/editMentor/${id}`);
  //   setOpenPopup(null);
  // };

  const onViewDetails = (rowData) => {
    const { id } = rowData;
    navigate(`/diactoAdmin/allMentors/${id}`);
    setOpenPopup(null);
  };
  
  const onEditMentor = (rowData) => {
    const { id } = rowData;
    navigate(`/diactoAdmin/editMentor/${id}`);
    setOpenPopup(null);
  };

  // Show the delete confirmation dialog
  const onDeleteWithConfirmation = (rowData) => {
    const { id } = rowData;
    setMentorToDelete(id);
    console.log("id", id);
    setShowDeleteConfirmation(true);
  };

  // Delete a mentor from the server
  const onDeleteMentor = async () => {
    try {
      const response = await axios.delete(`${BASEURL}/api/delete-mentor`, {
        data: { schoolId, userId: mentorToDelete },
      });
      console.log(response.data.message);
      setShowDeleteConfirmation(false);
      toast.success("Mentor Profile deleted successfully!");
      axios.get(`${BASEURL}/api/fetch-all-mentors-diacto`)
        .then(res => setMentorsData(res.data.mentorsData || []));
      setOpenPopup(null);
    } catch (error) {
      console.error("Error deleting mentor:", error);
      toast.error("Error deleting mentor");
    }
  };

  // const onDeleteMentor = async () => {
  //   try {
  //     const response = await axios.delete(`${BASEURL}/api/delete-mentor/${mentorToDelete}`);
  //     console.log(response.data.message);
  //     setShowDeleteConfirmation(false);
  //     toast.success("Mentor Profile deleted successfully!");
  //     axios.get(`${BASEURL}/api/fetch-all-mentors-diacto`)
  //       .then(res => setMentorsData(res.data.mentorsData || []));
  //     setOpenPopup(null);
  //   } catch (error) {
  //     console.error("Error deleting mentor:", error);
  //     toast.error("Error deleting mentor");
  //   }
  // };

  // Cancel the delete operation
  const cancelDelete = () => {
    setMentorToDelete(null);
    setShowDeleteConfirmation(false);
  };

  const options = {
    pagination: 'local', // or 'local'
    paginationSize: 10, // number of rows per page
  };

  const toggleMentorStatus = async (id, currentStatus) => {
    try {
      const response = await axios.patch(`${BASEURL}/api/toggle-mentor-status/${id}`, {
        is_active: !currentStatus
      });
      console.log(response.data.message);
      toast.success("Mentor status updated successfully!");
      axios.get(`${BASEURL}/api/fetch-all-mentors-diacto`)
        .then(res => setMentorsData(res.data.mentorsData || []));
    } catch (error) {
      console.error("Error updating mentor status:", error);
      toast.error("Error updating mentor status");
    }
  };

  return (
    <>
      <div className='mx-4 border-b border-gray-200'>
        <div className='pt-2 pb-3 flex justify-between'>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900   font-bold cursor-pointer">
              Mentors
            </p>
          </div>
          <div className='flex flex-row justify-between flex-x-2'>
            <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
              <div className="grid place-items-center w-12 text-gray-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Search Mentor Name"
                onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <Link to={`/diactoAdmin/createMentor`}>
              <button type="button" className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                <span className='text-sm'>Add Mentor</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* Render the ReactTabulator component with the table data and columns */}
      <ReactTabulator
      // key={data.id}
        data={data}
        columns={columns}
        layout="fitColumns"
        responsiveLayout="hide"
        tooltips={true}
        options={options}
        className="custom-tabulator-styles"
      />

      {/* Render the popup menu when a row is clicked */}
      {openPopup && (
        <div
          className="absolute bg-white shadow-lg rounded-lg py-2 w-48"
          style={{ top: popupPosition.top, left: popupPosition.left }}
        >
          <div className="cursor-pointer flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform   hover:bg-gray-100    " onClick={() => onViewDetails(rowData)}>
            <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill="currentColor"></path>
              <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" fill="currentColor"></path>
            </svg>

            <span className="mx-1">
              view profile
            </span>
          </div>

          <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform   hover:bg-gray-100    " onClick={() => onEditMentor(rowData)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>


            <span className="mx-1">
              Edit Profile
            </span>
          </div>

          <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform   hover:bg-gray-100    " onClick={() => onDeleteWithConfirmation(rowData)}>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
            </svg>


            <span className="mx-1">
              Delete Profile
            </span>
          </div>
        </div>
      )}

      {/* Render the delete confirmation dialog */}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400">
          <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl rtl:text-right   sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div className="flex items-center justify-center">
                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700  " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700  " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                </svg>

              </div>
              <div className="mt-2 text-center">
                <h3 className="text-lg font-medium leading-6 text-gray-800 capitalize " id="modal-title">Confirm Delete</h3>
                <p className="mt-2 text-sm text-gray-500 ">
                  Are you sure you want to delete this mentor?
                </p>
              </div>
            </div>
            <div className="mt-5 sm:flex sm:items-center sm:justify-end">
              <div className="sm:flex sm:items-center">
                <button
                  className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:mt-0 sm:w-auto sm:mx-2 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                  onClick={cancelDelete}
                >
                  Cancel
                </button>
                <button
                  className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-40"
                  onClick={onDeleteMentor}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default DiactoAllMentors;

