import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEmail } from "./EmailContext.js"; // Adjust the path accordingly
import axios from "axios";
import { BASEURL } from "../../../constants.js";
import toast from 'react-hot-toast';
import Image from '../../assets/image.png'

const ForgetPassword = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(""); // New state for error handling
    const { setEmail: setEmailContext } = useEmail();
    const navigate = useNavigate();

    const handleRequestOTP = async (e) => {
        e.preventDefault();
        try {
            // Check if the email exists in the database
            const response = await axios.post(`${BASEURL}/api/check-email-exists`, {
                email: email,
            });
            const { exists } = response.data;

            if (exists) {
                // If the email exists, send a request to the server to send OTP
                const otpResponse = await axios.post(`${BASEURL}/api/send-otp`, {
                    email: email,
                });
                console.log(otpResponse.data.message);

                // Set the email in the context using setEmail from useEmail
                setEmailContext(email);

                // Redirect to the OTP verification page
                navigate("/verify-otp");
            } else {
                // Handle the case when the email does not exist in the database
                setError("Entered email does not exist.");
                toast.error("An error occurred during the OTP request. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            // Handle error, e.g., show an error message to the user
            setError("An error occurred during the OTP request. Please try again.");
            toast.error("An error occurred during the OTP request. Please try again.");
        }
    };

    return (
        <div>

            <div className="bg-white dark:bg-gray-900">
                <div className="flex justify-center h-screen">
                    {/* <div className="hidden lg:block lg:w-3/5 cover-image bg-center h-full ">
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-30">
                            <div>
                                <h2 className="text-4xl font-bold text-white">Brand</h2>

                                <p className="max-w-xl mt-3 text-gray-300">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto sit consectetur nam accusamus possimus autem incidunt molestiae quibusdam aliquid, impedit nisi perspiciatis odio ducimus cumque nostrum! Quas, illum vel sequi molestiae vero corporis, accusantium velit, molestias totam necessitatibus voluptatem quam. Natus quaerat a ullam facere, cupiditate non itaque culpa aliquam?</p>
                            </div>
                        </div>
                    </div> */}


                    <div class="lg:w-3/5 bg-black h-full hidden lg:block ">
                        <img class="object-cover w-full h-full bg-gray-900 bg-opacity-30" src={Image} alt="Side" />
                    </div>
                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/5">
                        <div className="flex-1">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">BlissIQ</h2>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Forgot Password?</p>

                                <p className="max-w-xl mt-3 text-gray-400"> We will send a verification code to this email or phone number, if
                                    it matches an existing account.</p>
                            </div>

                            <div className="mt-8">
                                <form>
                                    <div>
                                        <label for="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email</label>

                                        <input type="email"
                                            placeholder="ex: johndoe@gmail.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            onClick={handleRequestOTP}
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Send OTP
                                        </button>
                                    </div>

                                </form>

                                <p className="mt-6 text-sm text-center text-gray-400"><Link to="/login" className="text-blue-500 focus:outline-none hover:text-red-500">Return to main page</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword