import { useState } from "react";
import { BASEURL } from '../../../../constants';
import axios from "axios";

const TestThumbnail = ({ validateQuestion, autoSaveQuestion, setDeleteQuestionId, q, currentQuestionId, index, setQuestion, questionsArray, setQuestionsArray, question, setCurrentQuestionId, selectedTestId, setShowModal }) => {



    const [isHovering, setIsHovering] = useState(false);
    console.log('q ', q);

    async function switchSlide(questionId) {
        /*
            if it doesnt match the 
            we need to set q to the new q 
            need the array get the id on which user clicked 
        */
        //save the previos slide 

        if (!validateQuestion()) {
            // If validation fails, return early
            return;
        }
        autoSaveQuestion().then((response) => {
            if (response.data.status === 'successfully saved') {
                //q is saved we can now set the questions array and set the q to the newly created q 
                setQuestionsArray((questionArray) => {
                    const newQuestionArray = questionArray.map((q) => {
                        if (q.id === currentQuestionId) {
                            return { ...question }
                        } else {
                            return q;
                        }
                    });
                    return newQuestionArray;
                })
                const q = questionsArray.find(item => item.id === questionId);
                console.log('q ', q, questionId)
                setQuestion(q);
                setCurrentQuestionId(q.id);
            }
        })
    }

    async function saveQuestion(q) {
        const response = await axios.post(`${BASEURL}/api/save-q`, {
            questionId: currentQuestionId
        })


    }

    function handleQuestionDelete(event, questionId) {
        event.stopPropagation();
        setShowModal(true);
        setDeleteQuestionId(questionId);
    }
    return (
        <div className={`shrink-0 flex items-center justify-center relative p-1 w-10 h-10 bg-white`}
            style={q.id === currentQuestionId ? { cursor: 'pointer', overflow: 'hidden', border: '2px solid #382F8A', backgroundColor: '#382F8A', color: 'white' } : { cursor: 'pointer', overflow: 'hidden', border: "1px solid #E0E0E0" }}
            onClick={() => { switchSlide(q.id) }}
            onMouseEnter={() => { setIsHovering(true) }}
            onMouseLeave={() => { setIsHovering(false) }}
        >
            {
                isHovering ? (
                    <span className="absolute top-0 right-0" onClick={(e) => { handleQuestionDelete(e, q.id) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" width={"0.5rem"} height={'0.5rem'} stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </span>
                ) : (null)
            }
            <span className="">{index + 1}</span>
        </div>);
}

export default TestThumbnail;