import { useEffect, useState } from "react";
import YouTubeVideo from "./YouTubeVideo";
import PcVideo from "./PcVideo";

const VideoComponent = ({ setCurrentSlideData, slidesArray, setSlidesArray, slideId, contentId, data, isSorted, setIsDataSaved }) => {
console.log("rendering video component")
    const [videoData, setVideoData] = useState(null);
    
    const [reRenderChid, setReRenderChild] = useState(false);
    //when videoComponents parent re-renders then videoState is lost 
    // useEffect(() => {
    //     if (data) {
    //         console.log("saved data", data);
    //         setVideoData(data);
    //     } else {
    //         setVideoData({
    //             renderComponent: "pc_video_component"
    //         })
    //     }
    //     setReRenderChild(!reRenderChid);
    // }, [slideId, isSorted])

    // useEffect(() => {
    //     console.log("setting current Slide data ", "videoData", videoData)

    //     setCurrentSlideData((currentSlide) => {
    //         return {
    //             ...currentSlide, content: currentSlide.content.map((contentObject) => {
    //                 if (contentObject.id === contentId) {
    //                     return {
    //                         id: contentObject.id,
    //                         type: contentObject.type,
    //                         data: videoData
    //                     }
    //                 }
    //                 return {
    //                     ...contentObject
    //                 }
    //             })
    //         }
    //     })

    // }, [videoData])



    return (
        <>
            {
                data && (
                    <div className="flex flex-col justify-center items-center gap-4">
                        {/* {
                            !videoData.videoKey && (
                                <div className="flex space-x-4 mb-4">
                                    <label className="flex space-x-2">
                                        <input type="radio" value={`pc_video_component`} name="uploadOption" className="form-radio" checked={videoData.renderComponent === "pc_video_component"} onChange={() => {
                                            setVideoData({ renderComponent: 'pc_video_component' })
                                        }} />
                                        <span>Upload</span>
                                    </label>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" value={`yt_component`} name="uploadOption" className="form-radio" checked={videoData.renderComponent === "yt_component"} onChange={() => {
                                            setVideoData({ renderComponent: 'yt_component' })
                                        }} />
                                        <span>Embed link</span>
                                    </label>
                                </div>
                            )
                        } */}
                        {
                            data.renderComponent === 'pc_video_component' && (
                                <PcVideo setCurrentSlideData={setCurrentSlideData} videoData={data} setVideoData={setVideoData} reRenderChid={reRenderChid} contentId={contentId} />
                            )
                        }
                        {
                            data.renderComponent === "yt_component" && (
                                <YouTubeVideo videoData={data} setVideoData={setVideoData} reRenderChid={reRenderChid} />
                            )
                        }
                    </div>
                )
            }
        </>

    )

    // if (videoData.renderComponent === "yt_component") {
    //     return (
    //         <YouTubeVideo videoData={videoData} setVideoData={setVideoData} reRenderChid={reRenderChid} />
    //     )
    // }

    // if (videoData.renderComponent === "pc_video_component") {
    //     return <PcVideo setCurrentSlideData={setCurrentSlideData} videoData={videoData} setVideoData={setVideoData} reRenderChid={reRenderChid} contentId={contentId} />
    // }
}

export default VideoComponent;