import { ReactComponent as IconButton } from '../assets/Icon Button.svg';
import { ReactComponent as TestSvg } from '../assets/Test svg.svg';
import { ReactComponent as Notes } from '../assets/Notes svg 1.svg';
import TestModal from '../Student/Course Consumption/TestModal';
import { useState } from 'react';
import Check from '../Student/icons/Check';

const Sidebar = ({ attemptNumber, setIsTestSubmitted, setShowPassModal, setShowFailModal, setIsTestStarted, setAttemptNumber, setTestStartTime, setTestName, sidebarToggle, setSidebarToggle, sidebarData, selectedSubtopicId, setSelectedSubtopicId, setTopicName, setSubtopicName, setSelectedTestId }) => {
    console.log("rendering sidebar ", sidebarToggle);
    const [testModal, setTestModal] = useState({
        showModal: false,
        testId: null
    });

    const defaultStyle = {
        margin: ' 12px 0 0', boxShadow: '0 0 9.5px 0 rgba(57, 49, 133, 0.04)', backgroundColor: '#fcfbfd'
    }
    return (
        <>
            {
                testModal.showModal &&
                <TestModal attemptNumber={attemptNumber} setIsTestSubmitted={setIsTestSubmitted} setShowPassModal={setShowPassModal} setShowFailModal={setShowFailModal} setIsTestStarted={setIsTestStarted} setAttemptNumber={setAttemptNumber} setTestStartTime={setTestStartTime} setSidebarToggle={setSidebarToggle} setTestModal={setTestModal} testId={testModal.testId} setSelectedTestId={setSelectedTestId} setSelectedSubtopicId={setSelectedSubtopicId} />
            }
            <div className={`transition-all flex flex-col duration-300 relative  ${sidebarToggle ? '2xl:w-1/6 w-[300px]' : 'w-12 items-center overflow-x-hidden overflow-y-hidden'}
                      
                        h-full overflow-y-scroll  pb-0 z-40`}

                style={{ backgroundColor: '#f5f4fd' }}
            >
                <div className={`w-full justify-end flex items-center p-2`}>
                    <button onClick={() => {
                        setSidebarToggle((sidebarToggle) => {
                            return !sidebarToggle;
                        })
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
                        </svg>

                    </button>
                </div>
                <div className={`flex justify-between p-2 pt-4 ${sidebarToggle ? 'block':'hidden'}`}>
                    <span className="font-bold">Topics</span>
                </div>

                {
                    sidebarData && (
                        <section className={`p-2 overflow-y-scroll ${sidebarToggle ? 'block':'hidden'}`}>
                            {
                                sidebarData.topics.map((topic, index) => (
                                    <div className="p-2">
                                        <div className="flex gap-3">
                                            <div className="w-6 h-6 rounded-md text-center font-medium" style={{ backgroundColor: '#e6e3fa' }} >{index + 1}</div>
                                            <div className="font-medium">{topic.name}</div>
                                        </div>
                                        <div>
                                            {
                                                topic.content.map((contentObject) => {
                                                    if (contentObject.type === 'slide-group') {
                                                        return (
                                                            <div onClick={() => {
                                                                setSelectedSubtopicId(contentObject.id);
                                                                setTopicName(topic.name);
                                                                setSubtopicName(contentObject.name);
                                                                setSelectedTestId(null)
                                                            }} className='flex justify-around items-center gap-3 p-2 px-3 rounded-xl' style={selectedSubtopicId === contentObject.id ? { ...defaultStyle, border: 'solid 1px #fa9248' } : { ...defaultStyle, border: 'solid 1px #ede8ff' }}>
                                                                <div className='flex justify-center items-center w-10 h-10 rounded-full' style={{ backgroundColor: '#dfdcf9' }} >
                                                                    <Notes />
                                                                </div>
                                                                <div className='grow'>
                                                                    <div className='font-medium'>{contentObject.name}</div>
                                                                    <div className='font-medium'>{contentObject.slideCount} slides</div>
                                                                </div>
                                                                {
                                                                    contentObject.status === 'complete' ? (
                                                                        <Check />
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                <div>
                                                                    <IconButton />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    if (contentObject.type === 'test') {
                                                        return (
                                                            <div onClick={() => {
                                                                setTestModal({ showModal: true, testId: contentObject.id });
                                                                setTopicName(topic.name);
                                                                setTestName(contentObject.name)
                                                            }} className='flex justify-around items-center gap-3 p-2 px-3 rounded-xl' style={{ border: 'solid 1px #ede8ff', margin: ' 12px 0 0', boxShadow: '0 0 9.5px 0 rgba(57, 49, 133, 0.04)', backgroundColor: '#fcfbfd' }}>
                                                                <div className='flex justify-center items-center w-10 h-10 rounded-full' style={{ backgroundColor: '#dfdcf9' }} >
                                                                    <TestSvg />
                                                                </div>
                                                                <div className='grow'>
                                                                    <div className='font-medium'>{contentObject.name}</div>
                                                                    <div className='font-medium'>{contentObject.numberOfQuestions} questions</div>
                                                                </div>
                                                                {
                                                                    contentObject.status === 'complete' ? (
                                                                        <Check />
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                <div>
                                                                    <IconButton />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </section>
                    )
                }
            </div>
        </>
    );
}

export default Sidebar;