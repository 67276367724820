import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill"; // Import Quill from react-quill
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import quill from "quill";

window.Quill = quill;

Quill.register("modules/imageResize", ImageResize);

const Editor = ({  slideId, placeholder, data , contentId , isSorted ,setIsDataSaved , setCurrentSlideData}) => {
  
  const [editorContent , setEditorContent] = useState("");

  useEffect(()=>{
    if(data){
      setEditorContent(data);
    }else{
      setEditorContent('');
    }
  },[])

  useEffect(()=>{
    setCurrentSlideData((currentSlide)=>{
      const newContent = currentSlide.content.map((contentObject)=>{
        if(contentObject.id === contentId ){
          return{
            id:contentObject.id,
            type:contentObject.type,
            data:editorContent
          }
        }
        return {
          ...contentObject
        }
      })
      return {...currentSlide , content: newContent}
    })
  },[editorContent])

  const handleChange = (html) => {
    console.log('pressed enter',editorContent);
    setIsDataSaved(false);
    setEditorContent(html);
  };
// { font: [] } [{ size: [] }]
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      [{ header: "1" }, { header: "2" }, ],
      ,
      [
        { list: "ordered" },
        { list: "bullet" }
      ],

      ["link"],
      [{ color: [] }, { background: [] }],
      ["clean"]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"]
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
    "color"
  ];

  return (
     <ReactQuill
      onChange={handleChange}
      value={editorContent}
      modules={modules}
      formats={formats}
      bounds="#root"
      placeholder={'Write something'}
    />
  );
};

export default Editor;