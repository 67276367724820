import { Toggle , LeftChevron , RightChevron } from "../../../Icons/icons"; 

const PreviewHeader = ({ courseInfo, setSidebarToggle, slidesArray, questionsArray, currentQuestionId, setCurrentSlideId, currentSlideId, setCurrentSlideData, setQuestion, setCurrentQuestionId }) => {


    function nextSlide() {
        //[{id}{id}{id}]
        const currentSlideIndex = slidesArray.findIndex((slide) => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex + 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex + 1]);
    }

    function previousSlide() {
        const currentSlideIndex = slidesArray.findIndex(slide => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex - 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex - 1]);

    }

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentSlideId === slidesArray[slidesArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentSlideId === slidesArray[0].id) return disableStyle;
        return {}
    }


    function disablePreviousQuestion() {
        if (questionsArray[0].id === currentQuestionId) {
            return disableStyle;
        }
    }

    function disableNextQuestion() {
        if (questionsArray[questionsArray.length - 1].id === currentQuestionId) {
            return disableStyle
        }
    }

    function previousQuestion() {

        const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
        const previousQuestion = questionsArray[currentQuestionsIndex - 1];
        setQuestion(previousQuestion);
        setCurrentQuestionId(previousQuestion.id)

    }

    function nextQuestion() {

        const currentQuestionsIndex = questionsArray.findIndex(question => question.id === currentQuestionId);
        const nextQuestion = questionsArray[currentQuestionsIndex + 1];
        setQuestion(nextQuestion);
        setCurrentQuestionId(nextQuestion.id)

    }

    //well whatever yar lets make it live today and lets tell shubham to test it 
    return (
        <>
            {
                courseInfo && (
                    <div className="flex justify-between px-6 py-2 bg-white">
                        <div className="flex items-center">
                            <div className="cursor-pointer" onClick={() => {
                                setSidebarToggle((sidebarToggle) => {
                                    return !sidebarToggle;
                                })
                            }}>
                                <Toggle />
                            </div>
                            <div className="flex flex-col items-start py-0 px-4">
                                <span className="text-2xl font-bold">{courseInfo.course_name}</span>
                                {/* <span className="text-gray-500 text-xs truncate max-w-96 2xl:text-lg" title={courseInfo.course_desc}>{courseInfo.course_desc}</span> */}
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            {/* <i class="fa-solid fa-palette icon-medium bordered-icon"></i> */}
                            {
                                slidesArray && (
                                    <div className='flex items-center gap-2'>
                                        {/*  */}
                                        <span style={disablePrevious()} onClick={previousSlide} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <LeftChevron />
                                        </span>
                                        <span style={disableNext()} onClick={nextSlide} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <RightChevron />
                                        </span>
                                    </div>
                                )
                            }
                            {
                                questionsArray && (
                                    <div className="flex items-center gap-2">
                                        <span style={disablePreviousQuestion()} onClick={previousQuestion} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <LeftChevron />
                                        </span>
                                        {/* <svg style={disablePreviousQuestion()} onClick={previousQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg> */}
                                        <span style={disableNextQuestion()} onClick={nextQuestion} className="flex items-center justify-center cursor-pointer border border-[#E0E0E0] w-[30px] h-[30px]">
                                            <RightChevron />
                                        </span>
                                        {/* <svg style={disableNextQuestion()} onClick={nextQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg> */}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default PreviewHeader;