import { v4 as uuidv4 } from "uuid";
import { useState } from 'react';
import axios from 'axios';
import { BASEURL } from "../../../../../constants";
import { RightChevron, CrossSVG } from "../../../../Icons/icons";

const ImageUploadModal = ({ setImageModal, setCurrentSlideData }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        console.log("dropped file ", file);
        if (file && file.type.startsWith('image/')) {
            // const uniqueId = uuidv4();
            setImageFile(file);
        }
        setIsHovered(false);
    };

    const handleBrowseFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {

            setImageFile(event.target.files[0]);
            // const uniqueId = uuidv4();
            // setUploadState({
            //     renderComponent: 'pc_video',
            //     videoId: uniqueId
            // });
            // Trigger S3 upload here if needed
        }
    };

    const handleUpload = async () => {
        setIsUploading(true);

        const uniqueId = uuidv4();
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('id', uniqueId);
        try {
            const response = await axios.post(`${BASEURL}/api/upload-image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setIsUploading(false);
            setImageModal(false)
            //generate the image url 
            const imageUrl = URL.createObjectURL(imageFile);

            const creatingContentObject = {
                id: uniqueId,
                data: { imgData: imageUrl, width: "", height: "" },
                type: 'Image'
            }

            setCurrentSlideData((currentSlideData) => {
                return { ...currentSlideData, content: [...currentSlideData.content, creatingContentObject], imageIdArray: [...currentSlideData.imageIdArray, uniqueId] }
            })

            // console.log("Response", response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };


    return (
        <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <div style={{ minWidth: '40%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                        <div className="text-right">
                            <span className="inline-block cursor-pointer" onClick={() => {
                                setImageModal(false)
                            }}>
                                <CrossSVG />
                            </span>
                        </div>
                        <div
                            className={`border-2 border-dashed p-4 rounded-md cursor-pointer ${isHovered ? 'border-blue-500 bg-blue-100' : 'border-gray-300'}`}
                            onDragOver={(e) => {
                                e.preventDefault();
                                setIsHovered(true);
                            }}
                            onDragLeave={() => setIsHovered(false)}
                            onDrop={handleFileDrop}
                        >
                            {
                                imageFile ? (
                                    <div>{imageFile.name}</div>
                                ) : (
                                    <>
                                        <div className="text-center">
                                            <div className="w-12 h-12 mx-auto mb-2 bg-gray-100 rounded-full flex items-center justify-center">
                                                <svg
                                                    className="w-6 h-6 text-gray-500"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7l9 6 9-6M5 19h14V8l-7 4.5L5 8v11z" />
                                                </svg>
                                            </div>
                                            <p className="text-gray-600">Upload or drag & drop file here</p>
                                            <button
                                                onClick={() => document.getElementById('fileInput').click()}
                                                className="text-blue-500 font-semibold"
                                            >
                                                Browse
                                            </button>
                                        </div>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            className="hidden"
                                            onChange={handleBrowseFileChange}
                                        />
                                    </>
                                )
                            }
                        </div>
                        <p className="text-sm text-gray-500 mt-2">The maximum size per file is 5 MB</p>
                        <div className="flex justify-end mt-4 space-x-2">
                            <button className="px-2 py-1 bg-gray-200 text-gray-700">Clear</button>
                            <button onClick={handleUpload} className="px-2 py-1 bg-customPrimaryPurple text-white">Upload</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageUploadModal;