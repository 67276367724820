import { useRef, useState } from "react";
import { RightChevron, CrossSVG } from "../../../../Icons/icons";
import { v4 as uuidv4 } from 'uuid';
const PptUploadModal = ({ setPptModal , setCurrentSlideData , setIsDataSaved }) => {
    const [powerPointUrl, setPowerPointUrl] = useState("");
    const dropdownRef = useRef(null);

    function dropdownClickHandler(e) {
        const dropdown = dropdownRef.current;

        // If `maxHeight` is empty or set to '0px', expand; otherwise, collapse
        if (!dropdown.style.maxHeight || dropdown.style.maxHeight === '0px') {
            const scrollHeight = dropdown.scrollHeight;
            dropdown.style.maxHeight = `${scrollHeight}px`;
        } else {
            dropdown.style.maxHeight = '0px';
        }
    }

    function handlePptUpload() {

        const uniqueId = uuidv4();
        if (powerPointUrl) {
            const contentObject = {
                id: uniqueId,
                data: powerPointUrl,
                type: 'Ppt'
            }

            setCurrentSlideData((currentSlide) => {
                return {
                    ...currentSlide, content: [...currentSlide.content , contentObject]
                }
            })
            setIsDataSaved(false);
            setPptModal(false);
        } else {
            //show error
        }
    }

    function handlePptUrl(e) {
        setPowerPointUrl(e.target.value);
    }

    return (
        <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <div style={{ minWidth: '40%' }} className="relative p-6 h-auto transform flex-col justify-evenly bg-white text-left shadow-xl transition-all">
                        <div className="text-right">
                            <span className="inline-block cursor-pointer" onClick={() => {
                                setPptModal(false)
                            }}>
                                <CrossSVG />
                            </span>
                        </div>
                        <div>
                            <textarea placeholder="Enter your link" className="w-full border border-borderColor p-2 outline-none" onChange={handlePptUrl} />
                        </div>
                        <div></div>
                        <div className="flex gap-2 justify-between">
                            <div className="">
                                <div id="dropdown-toggle" className="flex gap-2 cursor-pointer" onClick={dropdownClickHandler}>
                                    <div>See how to upload</div>
                                    <div>
                                        <RightChevron />
                                    </div>
                                </div>
                                <div ref={dropdownRef} id="ppt-upload-dropdown-content" className="max-h-0 transition-all duration-300 overflow-hidden">
                                    <h3 className="font-bold text-sm">
                                        Instructions:
                                    </h3>
                                    <ol className="list-decimal list-inside space-y-2 text-gray-700">
                                        <li>Save your presentation to OneDrive.com</li>
                                        <li>Open it in PowerPoint for the web</li>
                                        <li><span>Go to File</span> <RightChevron /> Share <RightChevron /> Embed.</li>
                                        <li>Click Generate to create the embed code</li>
                                        <li>Choose the correct Dimensions for your site</li>
                                        <li>Copy the embed code and close</li>
                                        <li>Paste the code above and upload</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="">
                                <button className="px-3 py-2 cursor-pointer">Clear</button>
                                <button onClick={handlePptUpload} className="px-3 py-2 bg-customPrimaryPurple text-white cursor-pointer">Upload</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PptUploadModal;