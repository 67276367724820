const CourseOverviewCard = ({ icon, title, number, }) => {
    return (
        <div className="flex flex-col w-56 shrink-0 bg-white border border-gray-200">
            <div className="flex gap-2 items-center px-4 py-2">
                <div className="bg-gray-200 rounded-lg p-1">
                    {icon}
                </div>
                <div>
                    <div>{title}</div>
                    <div>{number}</div>
                </div>
            </div>
            <hr />
            <div className="flex justify-between items-center px-4 py-2">
                <div className="text-sm">See details</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default CourseOverviewCard;