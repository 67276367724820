import { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import { LeftArrow ,DoubleRightArrows , PageGroupIcon , TestIcon  } from '../../../Icons/icons';
const NewPreviewSideBar = ({ sidebarToggle, sidebarData, setSelectedChapterId, setSelectedTestId,
    selectedChapterId, setTestName, selectedTestId, contentArray, setContentIndex, baseRoute
}) => {

    const buttonRef = useRef();
    const { courseId } = useParams();

    function handleToggleClick(event) {
        const accordionHeader = event.currentTarget.parentNode.parentNode;

        accordionHeader.classList.toggle('active');

        const accordionBody = accordionHeader.nextElementSibling;
        if (accordionHeader.classList.contains("active")) {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
        } else {
            accordionBody.style.maxHeight = 0;
        }
    }


    useEffect(() => {
        const toggles = document.querySelectorAll('.toggle');
        console.log("toggles.length", toggles.length)
        if (toggles.length > 0) {
            const firstToggle = toggles[0];
            console.log('firstToggle: preview', firstToggle);
            console.log('Element type: previe', firstToggle.constructor.name);

            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            firstToggle.dispatchEvent(event);
        }
    }, []);

    useEffect(() => {
        //this useEffect will run to update the height of the dropdown when user adds a slidegroup or a test
        console.log('updating the height')
        const accordionHeaders = document.querySelectorAll('div.accordion-header.active');

        accordionHeaders.forEach((accordionHeader) => {
            if (accordionHeader) {
                const accordionBody = accordionHeader.nextElementSibling;
                if (accordionHeader.classList.contains("active")) {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                } else {
                    accordionBody.style.maxHeight = 0;
                }
            }
        })

    }, [selectedChapterId, selectedTestId])

    function handleSubTopicClick(chapId) {
        //we have to update the contentIndex 
        //find the index we need to set 
        const index = contentArray.findIndex(contentObj => contentObj.id === chapId);
        console.log('contentIndex', index);
        setContentIndex(index);
        setSelectedChapterId(chapId);
        setSelectedTestId(null);
    }

    function handleTestClick(testId, testName) {
        const index = contentArray.findIndex(contentObj => contentObj.id === testId);
        setContentIndex(index);
        setSelectedTestId(testId);
        setTestName(testName)
        setSelectedChapterId(null);
    }

    return (
        <div style={{ width: sidebarToggle ? '0px' : '300px' }} className='h-full bg-[#382F8A0D] overflow-auto transition-all duration-300'>
            <div className='flex gap-2 items-center p-4 border-b border-borderColor'>
                {
                    baseRoute === 'quick-preview' && (
                        <Link to={`/course-builder/${courseId}`}>
                            <LeftArrow />
                        </Link>)
                }
                {
                    baseRoute === 'publish-view' && (<Link to="/myCourses/publishedCourses">
                        <LeftArrow />
                    </Link>)

                }
                {
                    baseRoute === 'draft-view' && (<Link to="/myCourses/draftCourses">
                        <LeftArrow />
                    </Link>)

                }
                {
                    baseRoute === 'admin-view' && (<Link to="/diactoAdmin/courses">
                        <LeftArrow />
                    </Link>)

                }
                {
                    baseRoute === 'verify-view' && (<Link to="/diactoAdmin/verifyCourses">
                        <LeftArrow />
                    </Link>)

                }
                {
                    baseRoute === 'store-view' && (<Link to="/store">
                        <LeftArrow />
                    </Link>)

                }
                Back
            </div>
            {
                sidebarData && <div className='p-3 mt-2'>
                    {
                        sidebarData.topics.map((topic, index) => {
                            return (
                                <div className='w-full rounded mb-1'>
                                    <div className=''>
                                        <div className='accordion-header py-1 flex justify-between items-center bg-transparent'>
                                            <div className='flex items-center w-2/3'>
                                                <button className='toggle'
                                                    onClick={handleToggleClick}
                                                    ref={buttonRef}
                                                >
                                                     <DoubleRightArrows />
                                                </button>
                                                <span className='text-base truncate w-full text-[#282828] font-medium'>{topic.name}</span>
                                            </div>

                                        </div>
                                        <div className='accordion-body max-h-0 overflow-hidden transition-all duration-300 ease-in-out'>
                                            <div className=''>
                                                {
                                                    topic.content.map((contentObject, index) => {
                                                        if (contentObject.type === 'slide-group') {
                                                            return (
                                                                <div name="single sub topic" className='flex justify-between items-center mb-1 p-1 pl-5' title={`${contentObject.name}`} style={selectedChapterId === contentObject.id ? { backgroundColor: '#DBD9F2', color: '#333' } : {}}>
                                                                    <div className='flex gap-2 w-2/3 items-center text-sm'>
                                                                        <PageGroupIcon />

                                                                        <label onClick={() => { handleSubTopicClick(contentObject.id); }} className='w-full truncate text-xs'>{contentObject.name}</label>

                                                                    </div>
                                                                    {
                                                                        selectedChapterId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        if (contentObject.type === 'test') {
                                                            return (
                                                                <div name="single sub topic" className='flex justify-between items-center mb-1 p-1 pl-5' title={`${contentObject.name}`}
                                                                    style={selectedTestId === contentObject.id ? { backgroundColor: '#DBD9F2', color: '#333' } : {}}
                                                                >
                                                                    <div className='flex w-2/3 gap-2 items-center text-sm'>
                                                                    <TestIcon />
                                                                        <label onClick={() => { console.log('test clicked?'); handleTestClick(contentObject.id, contentObject.name); }} className='w-full truncate text-xs'>{contentObject.name}</label>
                                                                    </div>
                                                                    {
                                                                        selectedTestId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>);
}

export default NewPreviewSideBar;